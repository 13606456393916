import React, { useEffect, useState } from "react";
import styled from "styled-components";

const OAuthLogin = () => {
  const LoginButton = styled.button`
    display: block;
    margin: 0 auto;
    width: 100%;
    padding-left: 40%;
    position: relative;
    font-size: 13px;
    font-weight: 500;
    color: #696b79;
    height: 50px;
    border: 1px solid #bfc0c6;
    background-color: #fff;
    border-radius: 5px;
    text-align: left;
  `;

  return (
    <div className="col-md-6 col-sm-6 col-xs-6">
      <LoginButton
        className="login-google login-button"
        onClick={(e) => {
          e.preventDefault();
          window.open(
            "https://core-api.diykitten-backend.site/oauth2/authorize/google",
            "_self"
          );
        }}
      >
        <span className="header-sprite login-gplus-logo"></span>
        GOOGLE
      </LoginButton>
    </div>
  );
};

export default OAuthLogin;
