import React from "react";
import styled from "styled-components";
import { s3Url } from "../static";
import RepDesignSwiper from "../components/RepDesignSwiper";

import { useNavigate } from "react-router-dom";
import Survey from "./Survey";

const BannerImage = () => {
  const imageUrl = `${s3Url}/main_key_visual.png`;
  const items = [
    {
      boardKey: 40,
      imageUrl:
        "https://mium-eunsun.s3.ap-northeast-2.amazonaws.com/art/3aee0198-c09b-47cc-9065-640925dfce6a.png",
      status: "POST",
    },
    {
      boardKey: 47,
      imageUrl:
        "https://mium-eunsun.s3.ap-northeast-2.amazonaws.com/art/80145ac9-023b-4dab-bdd2-a2842921f657.png",
      status: "POST",
    },
    {
      boardKey: 48,
      imageUrl:
        "https://mium-eunsun.s3.ap-northeast-2.amazonaws.com/art/de1c39fb-9e4b-40e0-aeb5-f55f2c30592a.png",
      status: "POST",
    },
    {
      boardKey: 49,
      imageUrl:
        "https://mium-eunsun.s3.ap-northeast-2.amazonaws.com/art/b62d4278-52a0-486b-a90b-59e97f33b9cc.png",
      status: "POST",
    },

    {
      boardKey: 51,
      imageUrl:
        "https://mium-eunsun.s3.ap-northeast-2.amazonaws.com/art/30749c94-dace-4e5f-820b-336623e911c5.png",
      status: "POST",
    },
    {
      boardKey: 52,
      imageUrl:
        "https://mium-eunsun.s3.ap-northeast-2.amazonaws.com/art/269af817-20a2-4ae7-817a-dbeeada32fae.png",
      status: "POST",
    },
    {
      boardKey: 53,
      imageUrl:
        "https://mium-eunsun.s3.ap-northeast-2.amazonaws.com/art/315fca33-7c96-43d8-8123-7bfdd1957efd.png",
      status: "POST",
    },
    {
      boardKey: 54,
      imageUrl:
        "https://mium-eunsun.s3.ap-northeast-2.amazonaws.com/art/18ebda26-cd10-4166-8d7f-cb0c1b28ac8b.png",
      status: "POST",
    },
    {
      boardKey: 55,
      imageUrl:
        "https://mium-eunsun.s3.ap-northeast-2.amazonaws.com/art/cc394d0a-3d41-4951-806f-fd83034a7bd6.png",
      status: "POST",
    },
  ];
  const navigate = useNavigate();

  const handleViewMore = () => {
    navigate("/projects/moru");
  };
  return (
    <BannerContainer>
      {/* <BannerSwiper> */}
      <ImageContainer
        imageUrl={imageUrl}
        className="rounded-[1rem]"
        onClick={() => navigate("/projects/moru")}
      />
      {/* </BannerSwiper> */}
      <div className="h-[2rem]" />
      <div className="flex flex-wrap  w-full justify-between px-[1rem] text-[1rem]">
        <div className=" font-bold">다양한 디자인을 시도하고 구경해보세요.</div>
        <div className="text-[0.9rem] cursor-pointer" onClick={handleViewMore}>
          더보기 {">"}
        </div>
      </div>
      <RepDesignSwiper items={items} />
      <Survey />
    </BannerContainer>
  );
};

const BannerContainer = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
`;

const ImageContainer = styled.div`
  width: 100%;
  padding-top: 30.34%; /* This value should be (image height / image width) * 100 */
  background-image: url(${(props) => props.imageUrl});
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  cursor: pointer;
`;

export default BannerImage;
