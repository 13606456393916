import React, { useState, useEffect, useRef } from "react";
import { useForm } from "react-hook-form";
import styled from "styled-components";
import * as api from "../api/apiClient";
import { CircleAlertIcon, PlusCircle, X } from "lucide-react";
import Modal from "../components/Common/Modal";
import { AnimatePresence, motion } from "framer-motion";
import PartsTagSearchBar from "../components/PartsTagSearchBar";
import ImageUploadModalContent from "../components/ImageUploadModalContent";
import LoadingModal from "../components/Common/LoadingModal";

const PageContainer = styled.div`
  max-width: 500px;
  margin: 0 auto;
  padding: 20px;
  height: 80vh;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const Input = styled.input`
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
`;

const Button = styled.button`
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  &:disabled {
    background-color: #cccccc;
  }
`;

const ModalContent = styled(motion.div)`
  background-color: white;
  border-radius: 8px;
  padding: 20px;
  width: 100%;
  max-width: 600px;
  max-height: 80vh;
  overflow-y: auto;
  position: relative;
`;

const CloseButton = styled.button`
  position: absolute;
  top: 2px;
  right: 2px;
  color: gray;
  &:hover {
    color: darkgray;
  }
`;

const PartsAddPage = ({ parentKey, partsData }) => {
  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    trigger, // This function allows you to manually trigger validation
    setValue,
  } = useForm({
    mode: "onBlur", // Validation occurs when fields lose focus
    shouldUnregister: false, // Prevent fields from being unregistered on re-renders
  });
  const modalRef = useRef(null);

  const [colorInformationArray, setColorInformationArray] = useState([
    {
      colorHexCode: null,
      partImageUrl: null,
    },
  ]);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalType, setModalType] = useState(""); // "imageUpload" or "alert"
  const [currentIndex, setCurrentIndex] = useState(null);
  const [alertContent, setAlertContent] = useState("");
  const [purchaseInfos, setPurchaseInfos] = useState([""]);
  const [tags, setTags] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (parentKey && partsData) {
      setPurchaseInfos(partsData.purchaseInfos);
      const colorinfoArr = [
        {
          colorHexCode: partsData?.colorHexCode,
          partImageUrl: partsData?.imageUrl,
        },
      ];
      const newColorArr = colorinfoArr.concat(
        partsData?.childPartDetails?.map((x) => {
          return {
            colorHexCode: x.colorHexCode,
            partImageUrl: x.imageUrl,
          };
        })
      );
      setColorInformationArray(newColorArr);

      setTags(partsData.tags);
      setValue("partsName", partsData.name, { shouldValidate: true });
      setValue("width", partsData.width, { shouldValidate: true });
      setValue("height", partsData.height, { shouldValidate: true });
      setValue("representativeColor", partsData.colorHexCode, {
        shouldValidate: true,
      });
      setValue("theme", "HALLOWEEN", { shouldValidate: true });
      setValue("position", partsData.themePosition[0]?.position ?? "ALL", {
        shouldValidate: true,
      });
    }
  }, [parentKey, partsData, setValue]);

  // Handle input change in purchaseInfos
  const handleInputChange = (index, value) => {
    const newPurchaseInfos = [...purchaseInfos];
    newPurchaseInfos[index] = value;
    setPurchaseInfos(newPurchaseInfos);
  };

  // Submit form
  const onSubmit = async (data) => {
    const uploadPayload = {
      partsName: data.partsName,
      width: data.width,
      height: data.height,
      purchaseInfos: purchaseInfos,
      theme: data.theme,
      position: data.position,
      tags: tags,
      childData: colorInformationArray?.map((x) => {
        return {
          ...x,
          isRepresentative: x.colorHexCode === data.representativeColor,
        };
      }),
    };
    const updatePayload = {
      parentPartsKey: parentKey,
      partsName: data.partsName,
      width: data.width,
      height: data.height,
      purchaseInfos: purchaseInfos,
      theme: data.theme,
      position: data.position,
      tags: tags,
      childData: colorInformationArray?.map((x) => {
        return {
          ...x,
          isRepresentative: x.colorHexCode === data.representativeColor,
        };
      }),
    };
    try {
      setLoading(true);
      if (parentKey) {
        await api.updateMoruParts(updatePayload);
      } else {
        await api.uploadMoruParts(uploadPayload);
      }
    } catch (error) {
      setLoading(false);
      alert("error", error);
      console.error("upload failed:", error);
      return;
    }
    setLoading(false);
    alert("success");
  };

  // File upload function
  const uploadImage = async (file, imageSort) => {
    try {
      const formData = new FormData();
      formData.append("uploadFile", file);
      formData.append("imageSort", "parts");
      const response = await api.fileUpload(formData);
      return response;
    } catch (error) {
      console.error("Error uploading the partImageUrl:", error);
    }
  };

  // Handle image upload and update colorInformationArray
  const handleImageUpload = async (e, index) => {
    const file = e.target.files[0];
    if (file) {
      const resultImage = await uploadImage(file, "parts");
      setColorInformationArray((prevArray) =>
        prevArray.map((oldElement, i) => {
          if (i === index) {
            return {
              ...oldElement,
              partImageUrl: `${resultImage}`,
            };
          }
          return oldElement;
        })
      );

      // Trigger validation manually (if necessary) to avoid re-triggering on modal close
      trigger();
    }
  };

  // Handle image deletion
  const handleDeleteImage = (e, index) => {
    setColorInformationArray((prevArray) =>
      prevArray.map((oldElement, i) => {
        if (i === index) {
          return {
            ...oldElement,
            partImageUrl: null,
          };
        }
        return oldElement;
      })
    );
  };

  // Open modal for image upload
  const handleImageUploadButton = (i) => {
    setCurrentIndex(i);
    setModalType("imageUpload");
    setIsModalOpen(true);
  };

  const handlePlusColor = () => {
    setColorInformationArray((prevArray) => [
      ...prevArray,
      { colorHexCode: null, partImageUrl: null },
    ]);
  };

  const handlePurchaseInfos = () => {
    setPurchaseInfos((prevArray) => [...prevArray, ""]);
  };
  const handleDeletePurchaseInfo = (e, index) => {
    setPurchaseInfos((prevArray) => prevArray.filter((_, i) => i !== index));
  };

  const handlePartsColor = (e, i) => {
    const color = e.target.value;
    setColorInformationArray((prevArray) =>
      prevArray.map((oldElement, index) => {
        if (i === index) {
          return { ...oldElement, colorHexCode: color };
        }
        return oldElement;
      })
    );
  };

  const handleDeleteColorInformation = (e, i) => {
    setColorInformationArray((prevArray) =>
      prevArray.filter((_, index) => {
        if (i === index) {
          return false;
        }
        return true;
      })
    );
  };

  const handleTagInput = (tags) => {
    setTags(tags);
  };

  const handleAlertModal = (content) => {
    setModalType("alert");
    setAlertContent(content);
    setIsModalOpen(true);
  };

  // Ensure form validation is triggered only when needed
  useEffect(() => {
    trigger(); // Trigger validation manually on specific field updates
  }, [colorInformationArray, purchaseInfos, trigger]);

  if (loading) {
    return <LoadingModal isOpen={true} />;
  }

  return (
    <PageContainer>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <>
          <label>재료명</label>
          <Input
            placeholder="재료명"
            {...register("partsName", {
              required: true,
              minLength: 1,
              maxLength: 20,
            })}
          />
          <hr />
          <label>실물 크기</label>
          <CircleAlertIcon
            onClick={() => {
              handleAlertModal(
                "업로드 되는 사진 기준의 직선 가로와 직선 세로 길이를 적어주세요."
              );
            }}
          />
          <Input
            {...register("width", {
              required: true,
              min: { value: 1, message: "최소 1의 값을 넣어주세요." },
            })}
            placeholder="가로길이"
          />
          mm
          <div>{errors?.width?.message}</div>
          <Input
            {...register("height", {
              required: true,
              min: { value: 1, message: "최소 1의 값을 넣어주세요." },
            })}
            placeholder="세로길이"
          />
          mm
          <div>{errors?.height?.message}</div>
          <hr />
          <label>태그 추가</label>
          <CircleAlertIcon
            onClick={() => {
              handleAlertModal(
                <>
                  파츠의 가로 세로, 외형이 같지만 색의 차이만 있을 경우에 옵션을
                  추가해 주세요.
                  <br /> 파츠 등록 취소의 사유가 될 수 있습니다.
                </>
              );
            }}
          />
          <PartsTagSearchBar
            setTags={handleTagInput}
            defaultTags={partsData?.tags ?? []}
          />
          <hr />
          <label>색 정보</label>
          {colorInformationArray?.map((x, i) => (
            <div key={i} className="flex">
              <input
                type="color"
                onChange={(e) => handlePartsColor(e, i)}
                value={x.colorHexCode || "#ffffff"}
              />
              <label>사진 업로드</label>
              <Button type="button" onClick={() => handleImageUploadButton(i)}>
                사진 업로드
              </Button>
              {x.partImageUrl && (
                <>
                  <img
                    src={x.partImageUrl}
                    alt="Part"
                    style={{ width: "50px", height: "50px" }}
                  />
                </>
              )}
              {colorInformationArray.length > 1 ? (
                <X onClick={(e) => handleDeleteColorInformation(e, i)}>
                  &times;
                </X>
              ) : null}
            </div>
          ))}
          <PlusCircle onClick={handlePlusColor} />
          <hr />
          <label>대표 색 지정</label>
          <select
            {...register("representativeColor")}
            placeholder="대표색 지정"
            defaultValue={
              partsData?.colorHexCode || colorInformationArray[0]?.colorHexCode
            }
          >
            {colorInformationArray?.map((x, idx) => (
              <option key={idx} value={x.colorHexCode}>
                {x.colorHexCode}
              </option>
            ))}
          </select>
          <hr />
          <label>테마 선택</label>
          {
            <>
              {"테마에 따라서 부위 추가 "}
              <select
                {...register("theme", { required: true })}
                placeholder="테마 지정"
                value={"HALLOWEEN"}
              >
                <option value={"HALLOWEEN"}>할로윈</option>
              </select>
              <hr />
              <label>테마 선택</label>
              <select
                {...register("position", { required: true })}
                placeholder="부위 지정"
              >
                {[
                  { 머리: "HEAD" },
                  { 눈: "EYE" },
                  { 입: "MOUTH" },
                  { 목: "NECK" },
                  { 날개: "WING" },
                  { 장식품: "ORNAMENT" },
                ].map((x, idx) => (
                  <option key={idx} value={Object.values(x)[0]}>
                    {Object.keys(x)[0]}
                  </option>
                ))}
              </select>
            </>
          }
          <hr />
          <label>구매처(상호명,링크)</label>
          <CircleAlertIcon
            onClick={() => {
              handleAlertModal(
                <>
                  잘못된 정보일 경우 삭제의 사유가 될 수 있습니다.
                  <br />
                  링크가 없다면 상호명만 적어주세요
                </>
              );
            }}
          />
          {purchaseInfos?.map((info, index) => (
            <div className="flex" key={index}>
              <Input
                key={index}
                placeholder="구매처"
                value={info}
                onChange={(e) => handleInputChange(index, e.target.value)}
                className="mb-2 "
              />
              {purchaseInfos.length > 1 ? (
                <X onClick={(e) => handleDeletePurchaseInfo(e, index)}>
                  &times;
                </X>
              ) : null}
            </div>
          ))}
          <PlusCircle onClick={handlePurchaseInfos} />
          <hr />
          <Button type="submit" disabled={loading}>
            파츠 업로드
          </Button>
        </>
      </Form>

      {isModalOpen && (
        <Modal
          isOpen={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          contentLabel="Modal"
          style={{
            overlay: {
              backgroundColor: "rgba(0, 0, 0, 0.75)",
              zIndex: 1000,
            },
            content: {
              top: "50%",
              left: "50%",
              right: "auto",
              bottom: "auto",
              transform: "translate(-50%, -50%)",
              padding: 0,
              border: "none",
            },
          }}
        >
          <AnimatePresence>
            <ModalContent
              ref={modalRef}
              initial={{ opacity: 0, scale: 0.8 }}
              animate={{ opacity: 1, scale: 1 }}
              exit={{ opacity: 0, scale: 0.8 }}
              transition={{ duration: 0.3 }}
            >
              <CloseButton onClick={() => setIsModalOpen(false)}>
                &times;
              </CloseButton>
              {modalType === "imageUpload" && currentIndex !== null ? (
                <ImageUploadModalContent
                  index={currentIndex}
                  colorInformationArray={colorInformationArray}
                  handleDeleteImage={handleDeleteImage}
                  handleImageUpload={handleImageUpload}
                />
              ) : null}
              {modalType === "alert" && <div>{alertContent}</div>}
            </ModalContent>
          </AnimatePresence>
        </Modal>
      )}
    </PageContainer>
  );
};

export default PartsAddPage;
