import React from "react";
import styled from "styled-components";
import { FaHeart } from "react-icons/fa"; // For the like icon
import { MdClose } from "react-icons/md"; // For the close icon
import { AiOutlineDownload } from "react-icons/ai"; // For the download icon

const ItemContainer = styled.div`
  background-color: white;
  border-radius: 15px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  position: relative;
  max-width: 350px;
  transition: transform 0.2s ease-in-out;
  cursor: pointer;

  &:hover {
    transform: scale(1.05);
  }
`;

const ImageWrapper = styled.div`
  position: relative;
  height: 300px;
`;

const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const Reflection = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 20px;
  background: linear-gradient(transparent, rgba(0, 0, 0, 0.15));
`;

const Overlay = styled.div`
  padding: 15px;
  display: flex;
  flex-direction: column;
`;

const ProfileSection = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
`;

const ProfileImage = styled.img`
  width: 30px;
  height: 30px;
  border-radius: 50%;
  margin-right: 10px;
`;

const ProfileName = styled.span`
  font-weight: bold;
  font-size: 14px;
`;

const LikeSection = styled.div`
  display: flex;
  align-items: center;
  margin-left: auto;
  color: #ff6666;
`;

const LikeIcon = styled(FaHeart)`
  margin-right: 5px;
`;

const CardTitle = styled.h3`
  font-size: 16px;
  font-weight: bold;
  margin: 10px 0;
`;

const Tags = styled.div`
  margin-top: 10px;

  .tag {
    background-color: #f0f0f0;
    border-radius: 5px;
    padding: 5px;
    font-size: 12px;
    margin-right: 5px;
    display: inline-block;
  }
`;

const Button = styled.button`
  margin-top: 15px;
  padding: 10px;
  background-color: #000;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
`;

const TopRightButtons = styled.div`
  position: absolute;
  top: 10px;
  right: 10px;
  display: flex;
  gap: 10px;
`;

const GalleryItem = ({ work, onImageLoad, boardKey, handleClickItem }) => {
  return (
    <ItemContainer onClick={() => handleClickItem(boardKey)}>
      <ImageWrapper>
        <Image src={work.imageUrl} alt={work.title} onLoad={onImageLoad} />
        <Reflection />
      </ImageWrapper>

      <Overlay>
        <ProfileSection>
          <ProfileImage src={work.profileImgUrl} alt="Profile" />
          <ProfileName>{work.nickName}</ProfileName>
          <LikeSection>
            <LikeIcon />
            <span>{work.likeCount}</span>
          </LikeSection>
        </ProfileSection>
        <CardTitle>{work.title}</CardTitle>
        <Tags>
          {work?.tags?.map((x) => {
            return <span className="tag">#{x}</span>;
          })}
        </Tags>
      </Overlay>
    </ItemContainer>
  );
};

export default GalleryItem;
