import React from "react";
import {
  ChevronDown,
  Clock,
  ThumbsUp,
  Eye,
  Palette,
  Heart,
} from "lucide-react";
import { useGallery } from "../../contexts/GalleryContext";

const SelectWithIcon = ({ label, value, onChange, options }) => (
  <div className="relative inline-block w-64">
    <label className="block text-sm font-medium text-gray-700 mb-1">
      {label}
    </label>
    <div className="relative ">
      <select
        value={value}
        onChange={onChange}
        className="block appearance-none w-full bg-white border border-gray-300 hover:border-gray-400 px-4 py-2 pr-8 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
      >
        {options.map((option) => (
          <option key={option.value} value={option.value} className="">
            {option.label}
          </option>
        ))}
      </select>
      <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
        <ChevronDown className="h-4 w-4" />
      </div>
    </div>
  </div>
);

const IconWrapper = ({ children }) => (
  <span className="inline-block mr-2 align-text-bottom">{children}</span>
);

// SortButtons component
const SortButtons = () => {
  const { sortType, updateSortType } = useGallery();

  const sortOptions = [
    {
      value: "RECENT",
      label: "최신순",
      icon: (
        <IconWrapper>
          <Clock className="h-4 w-4" />
        </IconWrapper>
      ),
    },
    {
      value: "LIKE",
      label: "찜 많은 순",
      icon: (
        <IconWrapper>
          <ThumbsUp className="h-4 w-4" />
        </IconWrapper>
      ),
    },
    {
      value: "VIEW",
      label: "조회수 순",
      icon: (
        <IconWrapper>
          <Eye className="h-4 w-4" />
        </IconWrapper>
      ),
    },
  ];

  return (
    <SelectWithIcon
      value={sortType}
      onChange={(e) => updateSortType(e.target.value)}
      options={sortOptions.map((option) => ({
        ...option,
        label: (
          <>
            {option.icon}
            {option.label}
          </>
        ),
      }))}
    />
  );
};

export default SortButtons;
