import React, { useRef } from "react";

const ImageUploadModalContent = ({
  index,
  handleDeleteImage,
  handleImageUpload,
  colorInformationArray,
}) => {
  // Create a ref to access the file input
  const fileInputRef = useRef(null);

  // Function to handle image deletion
  const handleDelete = (e, index) => {
    // Clear the file input value
    if (fileInputRef.current) {
      fileInputRef.current.value = null;
    }

    // Call the parent delete handler
    handleDeleteImage(e, index);
  };

  return (
    <div>
      <input
        type="file"
        onChange={(e) => handleImageUpload(e, index)}
        ref={fileInputRef} // Attach the ref to the input
      />
      {colorInformationArray[index]?.partImageUrl && (
        <>
          <button onClick={(e) => handleDelete(e, index)}>Delete Image</button>
          <img
            src={colorInformationArray[index].partImageUrl}
            alt="Parts"
            style={{ width: "100px", height: "100px" }}
          />
        </>
      )}
    </div>
  );
};

export default ImageUploadModalContent;
