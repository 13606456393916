import React, { useState } from "react";
import styled from "styled-components";
import PartsAddPage from "./PartsAddPage";
import Modal from "../components/Common/Modal";
import PartsGrid from "../components/PartsManagement/PartsList";
import PartsCreateUpdatePopup from "../components/Popup/PartsCreateUpdatePopup";

const PageContainer = styled.div`
  max-width: 500px;
  margin: 0 auto;
  padding: 20px;
`;

const PartsManagementPage = () => {
  //파츠 리스트를 뿌린다
  //파츠 중 하나를 누르면 모달이 뜨고, mode가 update로 된다
  //파츠추가하기를 누르면 mode가 add로 되고, 모달이 뜬다.
  const [isOpen, setIsOpen] = useState(false);
  return (
    <PageContainer>
      <div onClick={() => setIsOpen(true)}>파츠추가하기</div>
      <PartsGrid />
      <PartsCreateUpdatePopup
        isOpen={isOpen}
        onClose={() => {
          setIsOpen(false);
        }}
      />
    </PageContainer>
  );
};

export default PartsManagementPage;
