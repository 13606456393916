import React from "react";
import styled from "styled-components";
import { Instagram, LinkedinIcon, Youtube, TwitterIcon } from "lucide-react";

const Footer = () => {
  return (
    <FooterContainer>
      <SocialIcons>
        <InstagramIcon
          className="cursor-pointer"
          onClick={() =>
            window.open("https://www.instagram.com/diy_kitten_", "_blank")
          }
        />
        <LinkedinIcon
          className="cursor-pointer"
          onClick={() =>
            window.open(
              "https://www.linkedin.com/in/eunsun-namgung-975391186/",
              "_blank"
            )
          }
        />
        <TwitterIcon
          className="cursor-pointer"
          onClick={() => window.open("https://x.com/eunsun8004", "_blank")}
        ></TwitterIcon>
      </SocialIcons>
      {/* <FooterLinks>
        <FooterLink>이용약관</FooterLink>
        <FooterLink>개인정보처리방침</FooterLink>
        <FooterLink>운영정책</FooterLink>
        <FooterLink>공지사항</FooterLink>
        <FooterLink>도움센터</FooterLink>
      </FooterLinks> */}
      <CompanyInfo>
        DIY KITTEN | 대표: 남궁은선 | 개인정보보호책임자: 남궁은선
        <br />
        diykitten.com@gmail.com
        <br />
        평일 10:00 ~ 17:00 점심시간 12:00 ~ 13:00 (주말/공휴일 휴무)
      </CompanyInfo>
      <Copyright>Copyright © DIY KITTEN. All rights reserved.</Copyright>
    </FooterContainer>
  );
};

const FooterContainer = styled.footer`
  margin-top: 40px;
  padding: 20px 0;
  border-top: 1px solid #eee;
`;

const SocialIcons = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
`;

const InstagramIcon = styled(Instagram)`
  margin-right: 10px;
`;

const YoutubeIcon = styled(Youtube)``;

const FooterLinks = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
`;

const FooterLink = styled.a`
  margin: 0 10px;
  color: #666;
  text-decoration: none;
`;

const CompanyInfo = styled.p`
  text-align: center;
  font-size: 0.9rem;
  color: #666;
  line-height: 1.5;
`;

const Copyright = styled.p`
  text-align: center;
  font-size: 0.8rem;
  color: #999;
  margin-top: 20px;
`;

export default Footer;
