import React from "react";
import { useMyPage } from "../../contexts/MyPageContext";

const Tab = ({ label, isActive, onClick }) => (
  <button
    onClick={onClick}
    className={`px-4 py-2 font-medium text-sm focus:outline-none ${
      isActive
        ? "text-blue-600 border-b-2 border-blue-600"
        : "text-gray-500 hover:text-gray-700"
    }`}
  >
    {label}
  </button>
);

const TabGroup = ({ children }) => (
  <div className="flex border-b border-gray-200 mb-4">{children}</div>
);

// SearchTypeButtons component
const SearchTypeButtons = () => {
  const { searchType, updateSearchType } = useMyPage();

  return (
    <TabGroup>
      <Tab
        label="내 작품"
        isActive={searchType === "MY_ARTS"}
        onClick={() => updateSearchType("MY_ARTS")}
      />
      <Tab
        label="찜한 작품"
        isActive={searchType === "MY_LIKE_ARTS"}
        onClick={() => updateSearchType("MY_LIKE_ARTS")}
      />
    </TabGroup>
  );
};

export default SearchTypeButtons;
