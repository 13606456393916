const baseUrl = process.env.REACT_APP_BASE_URL;

export function getImageUrl({ imagePath }) {
  return `${baseUrl}/images${imagePath}`;
}

// Image compression function that maintains aspect ratio and uses PNG
export const compressImage = (dataURL, maxDimension) => {
  return new Promise((resolve) => {
    const img = new Image();
    img.onload = () => {
      const canvas = document.createElement("canvas");
      let width = img.width;
      let height = img.height;

      // Calculate the scaling factor if the image exceeds maxDimension
      if (width > maxDimension || height > maxDimension) {
        const scaleFactor = maxDimension / Math.max(width, height);
        width = Math.round(width * scaleFactor);
        height = Math.round(height * scaleFactor);
      }

      canvas.width = width;
      canvas.height = height;

      const ctx = canvas.getContext("2d");
      ctx.drawImage(img, 0, 0, width, height);

      // Use PNG format
      resolve(canvas.toDataURL("image/png"));
    };
    img.src = dataURL;
  });
};

export const goToSignin = () => {
  const token = localStorage.getItem("accessToken");

  if (!token) {
    window.location.href = "/signin";
  }
};
