import ProjectPage from "../../../components/Common/ProjectPage";
import GalleryGrid from "../../../components/Gallery/GalleryGrid";

const MoruPage = () => {
  const themes = [
    {
      name: "할로윈",
      theme: "HALLOWEEN",
      description: "무서운 할로윈 테마의 모루인형을 만들어보세요.",
    },
  ];

  return (
    <>
      <ProjectPage title="모루인형" themes={themes} />
      <GalleryGrid />
    </>
  );
};

export default MoruPage;
