import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

const SignupCompletePage = () => {
  const [countdown, setCountdown] = useState(3);
  const navigate = useNavigate();

  useEffect(() => {
    const timer = setInterval(() => {
      setCountdown((prevCount) => prevCount - 1);
    }, 1000);

    const redirect = setTimeout(() => {
      navigate("/");
      window.location.reload();
    }, 3000);

    return () => {
      clearInterval(timer);
      clearTimeout(redirect);
    };
  }, [navigate]);

  return (
    <div>
      <h1>회원가입이 완료되었습니다!</h1>
      <p>{countdown}초 후 홈페이지로 이동합니다...</p>
    </div>
  );
};

export default SignupCompletePage;
