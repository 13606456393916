import React, {
  createContext,
  useContext,
  useState,
  useCallback,
  useRef,
} from "react";
import * as api from "../api/apiClient";

const GalleryContext = createContext();

export const useGallery = () => useContext(GalleryContext);

export const GalleryProvider = ({ children }) => {
  const [works, setWorks] = useState([]);
  const [hasMore, setHasMore] = useState(true);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [searchTag, setSearchTag] = useState([]);
  const [sortType, setSortType] = useState("RECENT");
  const [page, setPage] = useState(0);
  const [boardType, setBoardType] = useState(null);

  const throttleTimer = useRef(null);

  const resetSearch = useCallback(() => {
    setWorks([]);
    setHasMore(true);
    setPage(0);
  }, []);

  const fetchWorks = useCallback(() => {
    if (loading || !hasMore) return;

    if (throttleTimer.current) {
      return;
    }

    throttleTimer.current = setTimeout(async () => {
      setLoading(true);
      setError(null);
      try {
        const data = await api.fetchWorks(page, searchTag, sortType, boardType);
        setWorks((prevWorks) => [...prevWorks, ...data.works]);
        setHasMore(data.hasMore);
        setPage((prevPage) => prevPage + 1);
      } catch (err) {
        if (err.response) {
          // The request was made, and the server responded with a status code
          // that falls out of the range of 2xx
          console.error(
            "Server responded with a status other than 2xx:",
            err.response.status
          );
        } else if (err.request) {
          // The request was made, but no response was received
          // This could indicate a CORS issue or a network error
          console.error("No response received:", err.request);
          if (err.request.status === 0) {
            console.error("CORS error detected");
          }
        } else {
          // Something happened in setting up the request that triggered an Error
          console.error("Error in setting up the request:", err.message);
        }
        setError(err.message || "An error occurred while fetching works");
        console.error("Error fetching works:", err);

        // Stop further attempts if the error is critical
        if (err.response && err.response.status >= 400) {
          setHasMore(false); // Stop further API requests if it's a server error
        }
      } finally {
        setLoading(false);
        throttleTimer.current = null;
      }
    }, 1000); // 1 second throttle
  }, [loading, hasMore, page, searchTag, sortType, boardType]);

  const updateBoardType = useCallback(
    (type) => {
      setBoardType(type);
      resetSearch();
    },
    [resetSearch]
  );

  const updateSearchTag = useCallback(
    (tag) => {
      setSearchTag((prev) => [...prev, tag]);
      resetSearch();
    },
    [resetSearch]
  );

  const updateMultiSearchTag = useCallback((tags) => {
    setSearchTag((prev) => [...prev, ...tags]);
  }, []);

  const removeSearchTag = useCallback(
    (tag) => {
      setSearchTag((prev) => prev.filter((t) => t !== tag));
      resetSearch();
    },
    [resetSearch]
  );

  const updateSortType = useCallback(
    (type) => {
      setSortType(type);
      resetSearch();
    },
    [resetSearch]
  );

  const value = {
    works,
    hasMore,
    loading,
    error,
    searchTag,
    sortType,
    fetchWorks,
    updateSearchTag,
    removeSearchTag,
    updateSortType,
    updateBoardType,
    updateMultiSearchTag,
  };

  return (
    <GalleryContext.Provider value={value}>{children}</GalleryContext.Provider>
  );
};

export default GalleryProvider;
