import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import * as api from "../api/apiClient";
const LogoutPage = () => {
  const [countdown, setCountdown] = useState(3);
  const navigate = useNavigate();

  useEffect(() => {
    const logout = async () => {
      // const { result } = await api.logout();
      // if (result === "SUCCESS") {
      localStorage.removeItem("accessToken");
      delete api.apiClient.defaults.headers.common["Authorization"];

      // 카운트다운 시작
      const timer = setInterval(() => {
        setCountdown((prevCount) => prevCount - 1);
      }, 1000);

      // 카운트다운이 끝나면 페이지 리로드
      const redirect = setTimeout(() => {
        navigate("/");
        window.location.reload();
      }, 3000);

      return () => {
        clearInterval(timer);
        clearTimeout(redirect);
      };
      // } else {
      //   alert("로그인 실패");
      //   navigate("/");
      // }
    };

    logout();
  }, [navigate]);

  return (
    <div>
      <h1>로그아웃중...</h1>
      <p>{countdown}초 후 홈페이지로 이동합니다...</p>
    </div>
  );
};

export default LogoutPage;
