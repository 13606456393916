import { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`
  body {
    @apply font-sans text-gray-900 bg-gray-100;
  }

  h1, h2, h3, h4, h5, h6 {
    @apply font-bold mb-2;
  }

  h1 {
    @apply text-3xl;
  }

  h2 {
    @apply text-2xl;
  }

  h3 {
    @apply text-xl;
  }
`;

export default GlobalStyle;
