import React, {
  createContext,
  useContext,
  useState,
  useCallback,
  useRef,
  useEffect,
} from "react";
import * as api from "../api/apiClient";

const MyPageContext = createContext();

export const useMyPage = () => useContext(MyPageContext);

export const MyPageProvider = ({ children }) => {
  const [works, setWorks] = useState([]);
  const [hasMore, setHasMore] = useState(true);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [searchType, setSearchType] = useState("MY_ARTS"); //MY_LIKE_ARTS
  const [page, setPage] = useState(0);
  const [profile, setProfile] = useState(null);
  const throttleTimer = useRef(null);

  useEffect(() => {
    const token = localStorage.getItem("accessToken");
    if (!token) {
      return;
    }
    setLoading(true);
    try {
      api.getMyPageInfo().then((value) => {
        setProfile(value);
      });
    } catch (error) {
      alert(`error to get user data`);
    }
    setLoading(false);
  }, []);
  const resetSearch = useCallback(() => {
    setWorks([]);
    setHasMore(true);
    setPage(0);
  }, []);

  const fetchWorks = useCallback(() => {
    if (loading || !hasMore) return;

    if (throttleTimer.current) {
      return;
    }

    throttleTimer.current = setTimeout(async () => {
      setLoading(true);
      setError(null);
      try {
        const data = await api.getMyPageArts(page, searchType);
        setWorks((prevWorks) => [...prevWorks, ...data.data]);
        setHasMore(!data.page.isLast); // Update hasMore correctly
        setPage((prevPage) => prevPage + 1);
      } catch (err) {
        if (err.response) {
          // The request was made, and the server responded with a status code
          // that falls out of the range of 2xx
          console.error(
            "Server responded with a status other than 2xx:",
            err.response.status
          );
        } else if (err.request) {
          // The request was made, but no response was received
          // This could indicate a CORS issue or a network error
          console.error("No response received:", err.request);
          if (err.request.status === 0) {
            console.error("CORS error detected");
          }
        } else {
          // Something happened in setting up the request that triggered an Error
          console.error("Error in setting up the request:", err.message);
        }
        setError(err.message || "An error occurred while fetching works");
        console.error("Error fetching works:", err);

        // Stop further attempts if the error is critical
        if (err.response && err.response.status >= 400) {
          setHasMore(false); // Stop further API requests if it's a server error
        }
      } finally {
        setLoading(false);
        throttleTimer.current = null;
      }
    }, 1000); // 1 second throttle
  }, [loading, hasMore, page, searchType]);

  const updateSearchType = useCallback(
    (type) => {
      setSearchType(type);
      resetSearch();
    },
    [resetSearch]
  );

  const value = {
    works,
    hasMore,
    loading,
    error,
    searchType,
    fetchWorks,
    updateSearchType,
    profile,
  };

  return (
    <MyPageContext.Provider value={value}>{children}</MyPageContext.Provider>
  );
};

export default MyPageProvider;
