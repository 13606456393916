// apiClient.js
import axios from "axios";

// Create an Axios instance
export const apiClient = axios.create({
  baseURL: "https://core-api.diykitten-backend.site",
});

export const fetchWorks = async (page, searchTag, sortType, searchType) => {
  try {
    const response = await apiClient.post("/board/list", {
      paging: {
        no: page,
        limit: 5,
      },
      criteria: {
        sortType: sortType,
        searchTags: searchTag || null,
        searchType: searchType || null,
      },
    });

    const { data, page: pageInfo } = response.data;

    return {
      works: data.map((item) => ({
        id: item.boardKey,
        imageUrl: item.imageUrl,
        createTime: item.createTime,
        likeCount: item.likeCount,
        viewCount: item.viewCount,
        isMyLike: item.isMyLike,
        nickName: item.nickName,
        profileImgUrl: item.profileImgUrl,
        tags: item.tags,
        type: item.type,
      })),
      hasMore: !pageInfo.isLast,
      totalPages: pageInfo.totalPages,
      totalElements: pageInfo.totalElements,
    };
  } catch (error) {
    console.error("Error fetching works:", error);
    throw error;
  }
};
/**
 * 
 * [GET] {host}/board/detail/{boardKey}

- response
{
    "nickName": "쿠리쿠리쿠쿠쿠",
    "comment": "hihihi",
    "imageUrl": "https://mium-eunsun.s3.ap-northeast-2.amazonaws.com/image/240914ALRG4V.jpeg",
    "likeCounts": 25,
    "viewCounts": 56,
    "tags": [
        "고양이",
        "강아지"
    ]
}
 */

export const fetchWorkInfo = async (boardKey) => {
  try {
    const response = await apiClient.get(`/board/detail/${boardKey}`);
    return response.data;
  } catch (error) {
    console.error("Error fetching works:", error);
    throw error;
  }
};

export const fetchLikeTags = async (string) => {
  try {
    const response = await apiClient.post(`/board/like/tags`, {
      searchLikeTag: string,
      limit: 10,
    });
    return response.data;
  } catch (error) {
    console.error("Error fetching works:", error);
    throw error;
  }
};

export const fetchLikePartsTags = async (string) => {
  try {
    const response = await apiClient.post(`/item/parts/like/tags`, {
      searchLikeTag: string,
      limit: 10,
    });
    return response.data;
  } catch (error) {
    console.error("Error fetching works:", error);
    throw error;
  }
};

export const fetchLikeList = async (boardKey) => {
  try {
    const response = await apiClient.get(
      `/board/detail/${boardKey}/like/users`
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching works:", error);
    throw error;
  }
};

export const join = async ({
  email,
  nickName,
  profileImageUrl,
  phoneNumber,
  termsKeys,
}) => {
  try {
    const response = await apiClient.post(`/join`, {
      email,
      nickName,
      profileImageUrl,
      phoneNumber,
      termsKeys,
    });
    return response;
  } catch (error) {
    console.error("Error fetching works:", error);
    throw error;
  }
};

export const checkNickName = async (value) => {
  try {
    const response = await apiClient.post(`/join/check/nick-name`, {
      value,
    });
    return !response.isExist;
  } catch (error) {
    console.error("Error fetching works:", error);
    throw error;
  }
};

export const fileUpload = async (value) => {
  try {
    const response = await apiClient.post(`/file/upload`, value);
    return response.data;
  } catch (error) {
    console.error("Error fetching works:", error);
    throw error;
  }
};

export const rembgImageUpload = async (value) => {
  try {
    const response = await apiClient.post(`https://ai-api.snaps.com/ai/rembg`, {
      imageData: value,
    });
    return response.data;
  } catch (error) {
    console.error("Error fetching works:", error);
    throw error;
  }
};

export const logout = async () => {
  try {
    const response = await apiClient.put(`/auth/logout`, {
      withCredentials: true,
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};
export const testAuthUser = async () => {
  try {
    const response = await apiClient.get(`/test/auth/user`, {
      withCredentials: true,
      withXSRFToken: true,
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
      },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const uploadMoruParts = async (data) => {
  try {
    const response = await apiClient.post(`/item/moru/parts`, data);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const updateMoruParts = async (data) => {
  try {
    const response = await apiClient.put(`/item/moru/parts`, data);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getThemeParts = async (item = "MORU", theme = "HALLOWEEN") => {
  try {
    const response = await apiClient.get(
      `/item/moru/parts?item=${item}&theme=${theme}`
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getAllParts = async () => {
  try {
    const response = await apiClient.get(`/item/moru/parts/all`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getPartsInfo = async (parentPartsKey) => {
  try {
    const response = await apiClient.get(`/item/moru/parts/${parentPartsKey}`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const deleteParts = async (parentPartsKey) => {
  try {
    const response = await apiClient.delete(
      `/item/moru/parts?parentPartsKey=${parentPartsKey}`
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const postArt = async (data) => {
  try {
    const response = await apiClient.post(`/item/moru/parts`, data);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const postMoruArts = async (data) => {
  try {
    const response = await apiClient.post(`/user/moru/arts`, data);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const postLike = async (boardKey) => {
  try {
    const response = await apiClient.post(`/board/${boardKey}/like`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getMoruResult = async (data) => {
  try {
    const response = await apiClient.post(
      `https://diykitten-backend.site/generate-image`,
      data
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};
export const getMyPageArts = async (page, searchType) => {
  try {
    const response = await apiClient.post(
      `/user/mypage/arts
`,
      {
        paging: {
          no: page,
          limit: 10,
        },
        criteria: {
          searchType, // MY_ARTS
        },
      }
    );

    return response.data;
  } catch (error) {
    console.error("Error fetching works:", error);
    throw error;
  }
};

export const getMoruImages = async () => {
  try {
    const response = await apiClient.get(
      `/item/moru?item=MORU&theme=HALLOWEEN`
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching works:", error);
    throw error;
  }
};

export const getMyPageInfo = async (boardKey) => {
  try {
    const response = await apiClient.get(`/user/mypage`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const postLikeOrNot = async (boardKey) => {
  try {
    const response = await apiClient.post(`/board/${boardKey}/like`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

//true /false

export const deleteWork = async (boardKey) => {
  try {
    const response = await apiClient.put(`/user/mypage?boardKey=${boardKey}`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getArtInfo = async (boardKey) => {
  try {
    const response = await apiClient.get(`/board/arts?boardKey=${boardKey}`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const updateBoard = async (data, boardKey) => {
  try {
    const response = await apiClient.put(
      `/user/moru/arts?boardKey=${boardKey}`,
      data
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getBoardUsedParts = async (boardKey) => {
  try {
    const response = await apiClient.get(`/board/detail/${boardKey}/parts`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const updateUserProfile = async (data) => {
  try {
    const response = await apiClient.put(`/user/profile`, data);
    return response.data;
  } catch (error) {
    throw error;
  }
};
/**
 * {
    "nickName":"쿠쿠닉네임수정",
    "profileImgUrl": "https://mium-eunsun.s3.ap-northeast-2.amazonaws.com/diy-kitten-web/images/moru_image/%ED%98%B8%EB%B0%95%EA%B3%A0%EC%96%91%EC%9D%B4_%EB%92%A4.png"
}
 */
//---------------//
