import ProjectPage from "../../../components/Common/ProjectPage";

const GemStitchPage = () => {
  const themes = [
    {
      name: "풍경화",
      description: "아름다운 자연 풍경을 보석으로 표현해보세요.",
    },
    { name: "동물", description: "귀여운 동물들을 보석으로 그려보세요." },
    {
      name: "추상화",
      description: "창의적인 추상 디자인을 보석으로 만들어보세요.",
    },
  ];

  const renderModalContent = (theme) => (
    <>
      <h2 className="text-2xl font-bold mb-4">
        {theme.name} 보석십자수 디자인
      </h2>
      <p>
        이곳에 보석십자수 {theme.name} 테마의 특별한 디자인 옵션을 추가하세요.
      </p>
      {/* 보석십자수 특화 컨텐츠 */}
    </>
  );

  return (
    <ProjectPage
      title="보석십자수"
      themes={themes}
      renderModalContent={renderModalContent}
    />
  );
};

export default GemStitchPage;
