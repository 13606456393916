import React from "react";
import styled from "styled-components";

const Survey = () => {
  return (
    <SurveySection>
      <SurveyTitle>설문조사</SurveyTitle>
      <SurveyContent>
        <div className="text-[1rem]">
          DIY KITTEN에서 여러분의 이야기를 기다립니다.
        </div>
        <div className="h-[10px]" />
        <div>
          <p>
            <a
              href="https://smore.im/form/qqJLyd5UGA"
              target="_blank"
              rel="noreferrer"
              className="text-blue-500"
            >
              수공예 입문자 설문 {">"}
            </a>
          </p>
          <p>
            <a
              href="https://smore.im/form/p3dTHbzLFG"
              target="_blank"
              rel="noreferrer"
              className="text-blue-500"
            >
              수공예 작가 설문 {">"}
            </a>
          </p>
          <p>
            <a
              href="https://smore.im/form/UIYvHDA5ui"
              target="_blank"
              rel="noreferrer"
              className="text-blue-500"
            >
              수공예 재료 및 키트 판매업체 설문 {">"}
            </a>
          </p>
        </div>
      </SurveyContent>
    </SurveySection>
  );
};

const SurveySection = styled.section`
  margin-top: 40px;
  padding: 20px;
  border-radius: 10px;
`;

const SurveyTitle = styled.h2`
  margin-bottom: 10px;
  font-weight: bold;
`;

const SurveyContent = styled.p`
  font-weight: bold;
  font-size: 0.9rem;
  line-height: 1.5;
  border-width: 2px;
  border-radius: 0.5rem;
  min-height: 200px;
  display: flex;
  flex-direction: column;
  justify-items: start;
  align-items: center;
  padding: 2rem;
`;

export default Survey;
