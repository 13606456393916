import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import styled from "styled-components";
import { fileUpload, getMyPageInfo, updateUserProfile } from "../api/apiClient";
import MyPageGrid from "../components/MyPage/MyPageGrid";
import LoadingModal from "../components/Common/LoadingModal";
import { goToSignin } from "../utils";

const MyPageContainer = styled.div`
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  font-family: Arial, sans-serif;
`;

const ProfileCard = styled.div`
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 20px;
  margin-bottom: 20px;
`;

const ProfileTitle = styled.h1`
  font-size: 24px;
  margin-bottom: 20px;
  color: #333;
`;

const ProfileContent = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
`;

const ProfileImage = styled.img`
  width: 100px;
  height: 100px;
  border-radius: 50%;
  object-fit: cover;
  margin-right: 20px;
`;

const ProfileInfo = styled.div`
  flex-grow: 1;
`;

const ProfileName = styled.h2`
  font-size: 20px;
  margin-bottom: 10px;
`;

const Button = styled.button`
  background-color: #007bff;
  color: white;
  border: none;
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;

  &:hover {
    background-color: #0056b3;
  }
`;

const CancelButton = styled(Button)`
  background-color: #6c757d;
  margin-left: 10px;

  &:hover {
    background-color: #545b62;
  }
`;

const StatsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  @media (max-width: 600px) {
    flex-direction: column;
  }
`;

const StatCard = styled.div`
  flex-basis: calc(33.333% - 10px);
  background-color: #f8f9fa;
  border-radius: 4px;
  padding: 15px;
  display: flex;
  align-items: center;
  margin-bottom: 10px;

  @media (max-width: 600px) {
    flex-basis: 100%;
  }
`;

const StatIcon = styled.span`
  font-size: 24px;
  margin-right: 10px;
`;

const StatInfo = styled.div`
  flex-grow: 1;
`;

const StatLabel = styled.p`
  font-size: 12px;
  color: #6c757d;
  margin: 0;
`;

const StatValue = styled.p`
  font-size: 18px;
  font-weight: bold;
  margin: 0;
`;

const Modal = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ModalContent = styled.div`
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  width: 90%;
  max-width: 400px;
`;

const FormGroup = styled.div`
  margin-bottom: 15px;
`;

const Label = styled.label`
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
`;

const Input = styled.input`
  width: 100%;
  padding: 8px;
  border: 1px solid #ced4da;
  border-radius: 4px;
`;

const FormActions = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
`;

const ErrorMessage = styled.div`
  background-color: #f8d7da;
  color: #721c24;
  padding: 10px;
  border-radius: 4px;
  margin-top: 20px;
`;

const Loading = styled.div`
  text-align: center;
  font-size: 18px;
  margin-top: 50px;
`;

const ImageUploadButton = styled(Button)`
  margin-top: 10px;
`;

const HiddenFileInput = styled.input`
  display: none;
`;

const MyPage = () => {
  goToSignin();
  const [profileData, setProfileData] = useState({
    nickName: "",
    profileImgUrl: "",
    myLikeCount: 0,
    myLikeRank: 0,
    myViewRank: 0,
  });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const [isEditing, setIsEditing] = useState(false);
  const [editedNickname, setEditedNickname] = useState("");
  const [editedProfileImgUrl, setEditedProfileImgUrl] = useState("");
  const fileInputRef = useRef(null);

  useEffect(() => {
    fetchMyPageInfo();
  }, []);

  const fetchMyPageInfo = async () => {
    setLoading(true);
    try {
      const response = await getMyPageInfo();
      setProfileData(response);
      setEditedNickname(response.nickName);
      setEditedProfileImgUrl(response.profileImgUrl);
    } catch (error) {
      setError("Failed to fetch profile data. Please try again later.");
    } finally {
      setLoading(false);
    }
  };

  const handleProfileUpdate = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const response = await updateUserProfile({
        nickName: editedNickname,
        profileImgUrl: editedProfileImgUrl,
      });
      setProfileData({
        ...profileData,
        nickName: editedNickname,
        profileImgUrl: editedProfileImgUrl,
      });
      setIsEditing(false);
    } catch (error) {
      setError("Failed to update profile. Please try again later.");
    } finally {
      setLoading(false);
    }
  };

  const handleImageUpload = async (event) => {
    const file = event.target.files[0];
    if (file) {
      try {
        const response = await uploadImage(file, "profile"); // Assuming 'profile' is the imageSort for profile pictures
        setEditedProfileImgUrl(response); // Assuming the API returns the URL of the uploaded image
      } catch (error) {
        setError("Failed to upload image. Please try again.");
      }
    }
  };

  const uploadImage = async (file, imageSort) => {
    try {
      const formData = new FormData();
      formData.append("uploadFile", file);
      formData.append("imageSort", imageSort);

      const response = await fileUpload(formData);
      return response;
    } catch (error) {
      console.error("Error uploading the image:", error);
      throw error;
    }
  };

  // Assume fileUpload is an imported function that handles the actual API call

  const triggerFileInput = () => {
    fileInputRef.current.click();
  };

  if (loading) return <LoadingModal isOpen />;

  return (
    <MyPageContainer>
      <ProfileCard>
        <ProfileContent>
          <ProfileImage
            src={isEditing ? editedProfileImgUrl : profileData.profileImgUrl}
            alt={profileData.nickName}
          />
          <ProfileInfo>
            <ProfileName>{profileData.nickName}</ProfileName>
            <Button onClick={() => setIsEditing(true)}>프로필 수정</Button>
          </ProfileInfo>
        </ProfileContent>
        <StatsContainer>
          <StatCard>
            <StatIcon>❤️</StatIcon>
            <StatInfo>
              <StatLabel>받은 찜 수</StatLabel>
              <StatValue>{profileData.myLikeCount}</StatValue>
            </StatInfo>
          </StatCard>
          <StatCard>
            <StatIcon>🏆</StatIcon>
            <StatInfo>
              <StatLabel>찜 순위</StatLabel>
              <StatValue>{profileData.myLikeRank}</StatValue>
            </StatInfo>
          </StatCard>
          <StatCard>
            <StatIcon>👁️</StatIcon>
            <StatInfo>
              <StatLabel>조회수 순위</StatLabel>
              <StatValue>{profileData.myViewRank}</StatValue>
            </StatInfo>
          </StatCard>
        </StatsContainer>
      </ProfileCard>
      <MyPageGrid />
      {isEditing && (
        <Modal>
          <ModalContent>
            <form onSubmit={handleProfileUpdate}>
              <FormGroup>
                <Label htmlFor="nickName">닉네임</Label>
                <Input
                  id="nickName"
                  type="text"
                  value={editedNickname}
                  onChange={(e) => setEditedNickname(e.target.value)}
                />
              </FormGroup>
              <FormGroup>
                <Label htmlFor="profileImgUrl">프로필 이미지</Label>
                <ProfileImage src={editedProfileImgUrl} alt="Profile Preview" />
                <ImageUploadButton type="button" onClick={triggerFileInput}>
                  다른 사진 선택
                </ImageUploadButton>
                <HiddenFileInput
                  ref={fileInputRef}
                  type="file"
                  accept="image/*"
                  onChange={handleImageUpload}
                />
              </FormGroup>
              <FormActions>
                <Button type="submit">저장</Button>
                <CancelButton type="button" onClick={() => setIsEditing(false)}>
                  취소
                </CancelButton>
              </FormActions>
            </form>
          </ModalContent>
        </Modal>
      )}

      {error && <ErrorMessage>{error}</ErrorMessage>}
    </MyPageContainer>
  );
};

export default MyPage;
