import React from "react";
import styled from "styled-components";
import Modal from "react-modal";
import { motion, AnimatePresence } from "framer-motion";

// Modal.setAppElement("#root");

const ModalContent = styled(motion.div)`
  @apply bg-white rounded-lg p-6 w-full max-w-md;
  max-height: 80vh;
  overflow-y: auto;
`;

const CloseButton = styled.button`
  @apply absolute top-2 right-2 text-gray-500 hover:text-gray-700 text-xl font-bold;
`;

const PartsContainer = styled.div`
  @apply mt-4;
`;

const PartItem = styled.div`
  @apply mb-6 p-4 border border-gray-200 rounded-lg;
`;

const PartImage = styled.img`
  @apply w-full h-32 object-cover rounded-md mb-2;
`;

const PartInfo = styled.p`
  @apply text-sm mb-1;
`;

const PartsPopup = ({ isOpen, onClose, parts }) => {
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      contentLabel="Likes"
      style={{
        overlay: {
          backgroundColor: "rgba(0, 0, 0, 0.75)",
          zIndex: 1000,
        },
        content: {
          top: "50%",
          left: "50%",
          right: "auto",
          bottom: "auto",
          marginRight: "-50%",
          transform: "translate(-50%, -50%)",
          padding: 0,
          border: "none",
          background: "white",
        },
      }}
    >
      <AnimatePresence>
        {isOpen && (
          <ModalContent
            initial={{ opacity: 0, scale: 0.8 }}
            animate={{ opacity: 1, scale: 1 }}
            exit={{ opacity: 0, scale: 0.8 }}
            transition={{ duration: 0.3 }}
          >
            <h3 className="text-xl font-bold mb-4">Likes</h3>
            <CloseButton onClick={onClose}>&times;</CloseButton>
            <PartsContainer>
              {Array.isArray(parts) && parts.length > 0 ? (
                parts.map((part, index) => (
                  <PartItem key={index}>
                    <PartImage src={part.partImgUrl} alt={part.name} />
                    <PartInfo>
                      <strong>Name:</strong> {part.partsName}
                    </PartInfo>
                    <PartInfo>
                      <strong>Source:</strong> {part.purchaseInfos}
                    </PartInfo>
                    <PartInfo>
                      <strong>Uploader:</strong> {part.partsUserNickName}
                    </PartInfo>
                    <PartInfo>
                      <strong>Size:</strong> {part.width} x {part.height}
                    </PartInfo>
                  </PartItem>
                ))
              ) : (
                <p>No parts information available.</p>
              )}
            </PartsContainer>
          </ModalContent>
        )}
      </AnimatePresence>
    </Modal>
  );
};

export default PartsPopup;
