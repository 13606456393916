import React from "react";
import styled from "styled-components";
import Modal from "react-modal";
import { motion, AnimatePresence } from "framer-motion";

const ModalContent = styled(motion.div)`
  @apply bg-white rounded-lg p-6 w-full max-w-md;
  max-height: 80vh;
  display: flex;
  flex-direction: column;
`;

const CloseButton = styled.button`
  @apply absolute top-2 right-2 text-gray-500 hover:text-gray-700 text-xl font-bold;
`;

const LikesContainer = styled.div`
  @apply mt-4 overflow-y-auto flex-grow;
`;

const LikeItem = styled.div`
  @apply flex items-center mb-4 p-2 border-b border-gray-200;
`;

const ProfileImage = styled.img`
  @apply w-10 h-10 rounded-full mr-4 object-cover;
`;

const UserInfo = styled.div`
  @apply flex flex-col;
`;

const UserName = styled.p`
  @apply font-semibold;
`;

const CatType = styled.p`
  @apply text-sm text-gray-600;
`;

const LikesPopup = ({ isOpen, onClose, likes }) => {
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      contentLabel="Likes"
      style={{
        overlay: {
          backgroundColor: "rgba(0, 0, 0, 0.75)",
          zIndex: 1000,
        },
        content: {
          top: "50%",
          left: "50%",
          right: "auto",
          bottom: "auto",
          marginRight: "-50%",
          transform: "translate(-50%, -50%)",
          padding: 0,
          border: "none",
          background: "white",
        },
      }}
    >
      <AnimatePresence>
        {isOpen && (
          <ModalContent
            initial={{ opacity: 0, scale: 0.8 }}
            animate={{ opacity: 1, scale: 1 }}
            exit={{ opacity: 0, scale: 0.8 }}
            transition={{ duration: 0.3 }}
          >
            <h3 className="text-xl font-bold mb-4">Likes</h3>
            <CloseButton onClick={onClose}>&times;</CloseButton>
            <LikesContainer>
              {Array.isArray(likes) && likes.length > 0 ? (
                likes.map((like, index) => (
                  <LikeItem key={index}>
                    <ProfileImage src={like.profileImage} alt={like.nickname} />
                    <UserInfo>
                      <UserName>{like.nickName}</UserName>
                      <img src={like.profileImageUrl} alt="profileImage" />
                    </UserInfo>
                  </LikeItem>
                ))
              ) : (
                <p>No likes yet.</p>
              )}
            </LikesContainer>
          </ModalContent>
        )}
      </AnimatePresence>
    </Modal>
  );
};

export default LikesPopup;
