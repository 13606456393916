import React from "react";
import { Helmet } from "react-helmet-async";
import { generateMetaTags } from "../utils/seoHelpers";

const GoodsPage = () => {
  return (
    <div>
      <Helmet>
        {generateMetaTags(
          "Official Goods - DIY KITTEN",
          "Shop our collection of official DIY KITTEN merchandise",
          "https://diygallery.com/images/goods-preview.jpg"
        )}
      </Helmet>
      <h1>Official Goods</h1>
      {/* Add goods browsing and shopping functionality here */}
    </div>
  );
};

export default GoodsPage;
