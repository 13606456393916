import { useCallback, useEffect, useState } from "react";
import HalloweenMoru from "./HalloweenMoru";
import * as api from "../../../api/apiClient";
import styled from "styled-components";
import PreviewPopup from "../../../components/Popup/PreviewPopup";
import { useNavigate } from "react-router-dom";
import { goToSignin } from "../../../utils";

const MoruModalContainer = styled.div`
  @media (min-width: 800px) {
    width: 80vw;
    max-width: 1000px;
  }
`;

const MoruModalContent = ({ selectedTheme, boardKey, onClose }) => {
  goToSignin();
  const [loading, setLoading] = useState(false);
  const [partsData, setPartsData] = useState(null);
  const [moruCharacter, setMoruCharacter] = useState(null);
  const [artInfoData, setArtInfoData] = useState(null); // New state variable
  const [isPreviewOpen, setIsPreviewOpen] = useState(false);
  const [previewData, setPreviewData] = useState(null);
  const navigate = useNavigate(); // This line should be at the top level of your component
  const getThemeParts = async () => {
    setLoading(true);
    try {
      const response = await api.getThemeParts("MORU", "ALL");
      if (response) {
        setPartsData(response);
      }
    } catch (e) {
      return;
    }
    setLoading(false);
  };

  const getMoruSelects = async () => {
    try {
      setLoading(true);
      const result = await api.getMoruImages();
      setMoruCharacter(result);
    } catch {
      alert("error when getting moru image");
      return;
    }
    setLoading(false);
  };

  const getArtInfos = useCallback(async () => {
    try {
      setLoading(true);
      const result = await api.getArtInfo(boardKey);
      setArtInfoData(result); // Store result in state
    } catch {
      alert("error when getting moru art infos");
      return;
    }
    setLoading(false);
  }, [boardKey]);

  useEffect(() => {
    if (boardKey) {
      getArtInfos();
    }
  }, [boardKey, getArtInfos]);

  useEffect(() => {
    getMoruSelects();
  }, []);

  useEffect(() => {
    getThemeParts();
  }, []);

  const handleSave = async () => {
    setLoading(true);
    setPreviewData(previewData);
    const requestApi = boardKey ? api.updateBoard : api.postMoruArts;
    try {
      // Make the POST request
      await requestApi(previewData, boardKey).then(() => {
        setIsPreviewOpen(true);
      });
      setLoading(false);
      setTimeout(() => {
        navigate("/projects/moru");
        window.location.reload();
      }, 100);
      return;
    } catch (error) {
      console.error("Error saving post:", error);
      // Handle error (e.g., show notification to the user)
    }
    setLoading(false);
  };

  const handlePreviewButton = (p) => {
    setPreviewData(p);
    setIsPreviewOpen(true);
  };

  const handleTemporarySaveButton = async (payload) => {
    setPreviewData(payload);
    const requestApi = boardKey ? api.updateBoard : api.postMoruArts;
    try {
      // Make the POST request
      await requestApi(payload, boardKey).then(() => {
        // setIsPreviewOpen(true);
        alert("임시 저장 완료");
      });
    } catch (error) {
      console.error("Error saving post:", error);
      // Handle error (e.g., show notification to the user)
    }
  };

  // if (!partsData || (boardKey && !artInfoData)) return "No data available";

  return (
    <>
      {previewData && isPreviewOpen && (
        <PreviewPopup
          id={boardKey}
          work={previewData}
          isOpen={isPreviewOpen}
          onClose={() => setIsPreviewOpen(false)}
          handleSaveButton={handleSave}
        />
      )}
      <MoruModalContainer>
        <h2 className="text-2xl font-bold mb-4">
          {selectedTheme} 모루인형 템플릿
        </h2>
        <HalloweenMoru
          partsData={partsData}
          moruData={moruCharacter}
          artInfoData={artInfoData} // Pass data to child component
          boardKey={boardKey}
          handlePreviewButton={handlePreviewButton}
          handleTemporarySaveButton={handleTemporarySaveButton}
          onClose={onClose}
        />
      </MoruModalContainer>
    </>
  );
};

export default MoruModalContent;
