// src/Swiper.js
import React, { useState, useEffect, useRef } from "react";
import useWindowWidth from "../hooks/useWindowWidth"; // Ensure the path is correct
import { useSwipeable } from "react-swipeable"; // Optional: For swipe gestures
import "./Swiper.css";
import PreviewCard from "./PreviewCard";

const Swiper = ({ items, autoPlayInterval = 3000 }) => {
  // Added autoPlayInterval prop
  const windowWidth = useWindowWidth();
  const [itemsToShow, setItemsToShow] = useState(4);
  const [currentIndex, setCurrentIndex] = useState(0);
  const totalItems = items.length;
  const swiperRef = useRef(null);
  const autoPlayRef = useRef(); // Ref to hold the auto-play function

  // Update itemsToShow based on window width
  useEffect(() => {
    if (windowWidth >= 1200) {
      setItemsToShow(4);
    } else if (windowWidth >= 992) {
      setItemsToShow(3);
    } else if (windowWidth >= 768) {
      setItemsToShow(2);
    } else {
      setItemsToShow(1);
    }
  }, [windowWidth]);

  // Handle Previous Slide
  const prevSlide = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? totalItems - itemsToShow : prevIndex - 1
    );
  };

  // Handle Next Slide
  const nextSlide = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex + 1 > totalItems - itemsToShow ? 0 : prevIndex + 1
    );
  };

  // Ensure currentIndex is within bounds when itemsToShow changes
  useEffect(() => {
    if (currentIndex > totalItems - itemsToShow) {
      setCurrentIndex(
        totalItems - itemsToShow >= 0 ? totalItems - itemsToShow : 0
      );
    }
  }, [totalItems, itemsToShow, currentIndex]);

  // Calculate the translateX percentage
  const translateX = `-${(100 / itemsToShow) * currentIndex}%`;

  // Swipe Handlers (Optional)
  const handlers = useSwipeable({
    onSwipedLeft: () => nextSlide(),
    onSwipedRight: () => prevSlide(),
    preventDefaultTouchmoveEvent: true,
    trackMouse: true, // Enable swipe with mouse for desktop
  });

  // Auto-Play Functionality
  useEffect(() => {
    autoPlayRef.current = nextSlide; // Assign nextSlide to the ref
  });

  useEffect(() => {
    const play = () => {
      autoPlayRef.current();
    };

    const interval = setInterval(play, autoPlayInterval);
    return () => clearInterval(interval); // Cleanup on unmount
  }, [autoPlayInterval]);

  // Optional: Pause auto-play on hover
  const [isHovered, setIsHovered] = useState(false);

  useEffect(() => {
    if (isHovered) {
      // If hovered, do not auto-play
      return;
    }
    const play = () => {
      autoPlayRef.current();
    };

    const interval = setInterval(play, autoPlayInterval);
    return () => clearInterval(interval);
  }, [isHovered, autoPlayInterval]);

  return (
    <div
      className={`swiper-container ${itemsToShow === 1 ? "single-item" : ""}`}
      style={{ "--items-to-show": itemsToShow }}
      {...handlers} // Attach swipe handlers
      onMouseEnter={() => setIsHovered(true)} // Pause on hover
      onMouseLeave={() => setIsHovered(false)} // Resume on hover out
    >
      <button
        className="swiper-button prev"
        onClick={prevSlide}
        aria-label="Previous Slide"
      >
        &#10094;
      </button>

      <div
        className="swiper-wrapper"
        ref={swiperRef}
        style={{
          transform: `translateX(${translateX})`,
        }}
      >
        {items.map((item, index) => (
          <div className="swiper-slide" key={index}>
            <div className="flex justify-center">
              <PreviewCard
                imageSrc={item.imageUrl}
                isSingle={itemsToShow === 1}
              />
            </div>
          </div>
        ))}
      </div>

      <button
        className="swiper-button next"
        onClick={nextSlide}
        aria-label="Next Slide"
      >
        &#10095;
      </button>
    </div>
  );
};

export default Swiper;
