import React, { createContext, useContext, useState, useEffect } from "react";
import * as api from "../api/apiClient";
import LoadingModal from "../components/Common/LoadingModal";

const ProfileContext = createContext();

export const useProfile = () => useContext(ProfileContext);

export const ProfileProvider = ({ children }) => {
  const [loading, setLoading] = useState(false);

  const [profile, setProfile] = useState(null);

  useEffect(() => {
    const token = localStorage.getItem("accessToken");
    if (!token) {
      return;
    }
    setLoading(true);
    try {
      api.getMyPageInfo().then((value) => {
        setProfile(value);
      });
    } catch (error) {
      alert(`error to get user data`);
    }
    setLoading(false);
  }, []);

  const value = {
    profile,
  };
  if (loading) return <LoadingModal isOpen />;

  return (
    <ProfileContext.Provider value={value}>{children}</ProfileContext.Provider>
  );
};

export default ProfileProvider;
