import React, {
  useState,
  useCallback,
  useMemo,
  useEffect,
  useRef,
} from "react";
import styled from "styled-components";
import Autosuggest from "react-autosuggest";
import debounce from "lodash/debounce";
import { fetchLikePartsTags } from "../api/apiClient";

const SearchContainer = styled.div`
  @apply mb-4;
`;

const StyledInput = styled.input`
  @apply w-full p-2 border border-gray-300 rounded-md;
`;

const TagBadge = styled.span`
  @apply inline-flex items-center px-2 py-1 rounded-full text-xs font-medium bg-blue-100 text-blue-800 mr-2 mb-2;
`;

const CloseButton = styled.button`
  @apply ml-1 text-blue-500 hover:text-blue-700;
`;

const AutoCompleteSearchBar = React.memo(({ setTags, defaultTags }) => {
  const [value, setValue] = useState("");
  const [suggestions, setSuggestions] = useState([]);
  const inputRef = useRef(null);
  const isComposingRef = useRef(false);
  const [searchTag, setSearchTag] = useState(defaultTags);
  useEffect(() => {
    setTags?.(searchTag);
  }, [searchTag, setTags]);

  const updateSearchTag = useCallback((tag) => {
    setSearchTag((prev) => [...prev, tag]);
  }, []);

  const removeSearchTag = useCallback((tag) => {
    setSearchTag((prev) => prev.filter((t) => t !== tag));
  }, []);

  const debouncedGetSuggestions = useMemo(
    () =>
      debounce((inputValue) => {
        fetchLikePartsTags(inputValue).then((x) => {
          setSuggestions(x.filter((e) => !searchTag?.includes(e)));
        });
      }, 300),
    [searchTag]
  );

  useEffect(() => {
    if (value) {
      debouncedGetSuggestions(value);
    } else {
      setSuggestions([]);
    }

    return () => {
      debouncedGetSuggestions.cancel();
    };
  }, [value, debouncedGetSuggestions]);

  const onSuggestionsFetchRequested = useCallback(() => {
    // This is now handled by the useEffect
  }, []);

  const onSuggestionsClearRequested = useCallback(() => {
    setSuggestions([]);
  }, []);

  const onSuggestionSelected = useCallback(
    (event, { suggestionValue }) => {
      updateSearchTag(suggestionValue);
      setValue("");
    },
    [updateSearchTag]
  );

  const handleKeyDown = useCallback(
    (e) => {
      if (e.key === "Enter" && !isComposingRef.current) {
        e.preventDefault();
        // Check if there's a highlighted suggestion
        const highlightedSuggestion = document.querySelector(
          ".react-autosuggest__suggestion--highlighted"
        );
        if (!highlightedSuggestion) {
          const currentInputValue = inputRef.current.value.trim();
          if (
            currentInputValue !== "" &&
            !searchTag.includes(currentInputValue)
          ) {
            updateSearchTag(currentInputValue);
            setValue("");
            inputRef.current.value = "";
          }
        }
      }
    },
    [searchTag, updateSearchTag]
  );

  const handleCompositionStart = () => {
    isComposingRef.current = true;
  };

  const handleCompositionEnd = () => {
    isComposingRef.current = false;
  };

  const inputProps = useMemo(
    () => ({
      id: "search-input",
      name: "search-input",
      placeholder: "태그로 검색...",
      value,
      onChange: (_, { newValue }) => setValue(newValue),
      onKeyDown: handleKeyDown,
      ref: inputRef,
      onCompositionStart: handleCompositionStart,
      onCompositionEnd: handleCompositionEnd,
    }),
    [value, handleKeyDown]
  );

  const renderInputComponent = useCallback((inputProps) => {
    const { ref, ...restInputProps } = inputProps;
    return <StyledInput ref={ref} {...restInputProps} />;
  }, []);

  const renderSuggestion = useCallback(
    (suggestion) => <div>{suggestion}</div>,
    []
  );

  return (
    <SearchContainer>
      <Autosuggest
        suggestions={suggestions}
        onSuggestionsFetchRequested={onSuggestionsFetchRequested}
        onSuggestionsClearRequested={onSuggestionsClearRequested}
        onSuggestionSelected={onSuggestionSelected}
        getSuggestionValue={(suggestion) => suggestion}
        renderSuggestion={renderSuggestion}
        inputProps={inputProps}
        renderInputComponent={renderInputComponent}
      />
      <div>
        {searchTag?.map((tag) => (
          <TagBadge key={tag}>
            {tag}
            <CloseButton onClick={() => removeSearchTag(tag)}>
              &times;
            </CloseButton>
          </TagBadge>
        ))}
      </div>
    </SearchContainer>
  );
});

export default AutoCompleteSearchBar;
