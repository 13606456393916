import React, { useState, useRef, useEffect, useCallback } from "react";
import styled from "styled-components";
import Modal from "../Common/Modal";
import { motion, AnimatePresence } from "framer-motion";
import PartsPopup from "./PartsPopup";
import LikesPopup from "./LikesPopup";
import {
  fetchLikeList,
  postLikeOrNot,
  getBoardUsedParts,
} from "../../api/apiClient";
import { HeartIcon } from "lucide-react";
import { FaHeart } from "react-icons/fa";
import { MdClose } from "react-icons/md";
import { AiOutlineDownload } from "react-icons/ai";
import { useSwipeable } from "react-swipeable";

const ImageWrapper = styled.div`
  position: relative;
`;

const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const Reflection = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 20px;
  background: linear-gradient(transparent, rgba(0, 0, 0, 0.15));
`;

const Overlay = styled.div`
  padding: 15px;
  display: flex;
  flex-direction: column;
`;

const ProfileSection = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
`;

const ProfileImage = styled.img`
  width: 30px;
  height: 30px;
  border-radius: 50%;
  margin-right: 10px;
`;

const ProfileName = styled.span`
  font-weight: bold;
  font-size: 14px;
`;

const LikeSection = styled.div`
  display: flex;
  align-items: center;
  margin-left: auto;
  color: #ff6666;
`;

const LikeIcon = styled(FaHeart)`
  margin-right: 5px;
`;

const CardTitle = styled.h3`
  font-size: 16px;
  font-weight: bold;
  margin: 10px 0;
`;

const Tags = styled.div`
  margin-top: 10px;

  .tag {
    background-color: #f0f0f0;
    border-radius: 5px;
    padding: 5px;
    font-size: 12px;
    margin-right: 5px;
    display: inline-block;
  }
`;

const Button = styled.button`
  margin-top: 15px;
  padding: 10px;
  background-color: #000;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
`;

const TopRightButtons = styled.div`
  position: absolute;
  top: 10px;
  right: 10px;
  display: flex;
  gap: 10px;
`;

const SwiperButtons = styled.div`
  position: absolute;
  top: 40%;
  width: 100%;
  display: flex;
  justify-content: space-between;
  transform: translateY(-50%);
  z-index: 1;

  button {
    background: white;
    border: 1px solid black;
    font-size: 24px;
    cursor: pointer;
    border-radius: 3rem;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30px;
    height: 30px;

    &:hover {
      background: rgba(255, 255, 255, 1);
    }
  }
`;

const WorkPopup = ({ id, work, isOpen, onClose, state, isLiked }) => {
  const [isPartsPopupOpen, setIsPartsPopupOpen] = useState(false);
  const [isLikesPopupOpen, setIsLikesPopupOpen] = useState(false);
  const [likeList, setLikeList] = useState([]);
  const [partsList, setPartsList] = useState([]);
  const [isLikedState, setIsLikedState] = useState(isLiked);
  const [loading, setLoading] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);

  const likesCount = Number(work.likeCounts);
  const images = [work.frontImgUrl, work.backImgUrl];
  const fetchLikeListFn = useCallback(async () => {
    const likeList = await fetchLikeList(id);
    setLikeList(likeList);
  }, [id]);

  const fetchPartsListFn = useCallback(async () => {
    setLoading(true);
    try {
      const partsList = await getBoardUsedParts(id);
      setPartsList(partsList);
    } catch (error) {
      alert(error);
    }
    setLoading(false);
  }, [id]);

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }
    return () => {
      document.body.style.overflow = "unset";
    };
  }, [isOpen]);

  const handlePartsClick = () => {
    setIsPartsPopupOpen(true);
    fetchPartsListFn();
  };

  const handleLikesClick = () => {
    if (likesCount > 0) {
      setIsLikesPopupOpen(true);
      fetchLikeListFn();
    }
  };

  const handleClose = () => {
    onClose();
    setIsPartsPopupOpen(false);
    setIsLikesPopupOpen(false);
  };

  const handleHeartIcon = async () => {
    try {
      await postLikeOrNot(id).then((res) => {
        setIsLikedState(res);
      });
    } catch (error) {
      alert(`error with like button`);
    }
  };

  const handlers = useSwipeable({
    onSwipedLeft: () =>
      setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length),
    onSwipedRight: () =>
      setCurrentIndex(
        (prevIndex) => (prevIndex - 1 + images.length) % images.length
      ),
    preventDefaultTouchmoveEvent: true,
    trackMouse: true,
  });

  if (state === "error") return <div>error</div>;
  return (
    <>
      <Modal isOpen={isOpen} onClose={handleClose}>
        <AnimatePresence>
          {isOpen && (
            <div className="relative">
              <ImageWrapper {...handlers}>
                <Image
                  src={images[currentIndex]}
                  alt={`작품 이미지 ${currentIndex + 1}`}
                />
                <Reflection />
                <TopRightButtons>
                  <MdClose size={24} onClick={handleClose} />
                </TopRightButtons>
              </ImageWrapper>

              <SwiperButtons>
                <button
                  onClick={() =>
                    setCurrentIndex(
                      (prevIndex) =>
                        (prevIndex - 1 + images.length) % images.length
                    )
                  }
                >
                  &#10094;
                </button>
                <button
                  onClick={() =>
                    setCurrentIndex(
                      (prevIndex) => (prevIndex + 1) % images.length
                    )
                  }
                >
                  &#10095;
                </button>
              </SwiperButtons>

              <Overlay>
                <ProfileSection>
                  {/* <ProfileImage src={work.profileImgUrl} alt="Profile" /> */}
                  <ProfileName>{work.nickName}</ProfileName>
                  <LikeSection>
                    <LikeIcon />
                    <span>{likesCount}</span>
                  </LikeSection>
                </ProfileSection>

                <CardTitle>{work.type}</CardTitle>
                <p>
                  <strong>조회수</strong> {work.viewCounts}
                </p>
                <Tags>
                  {Array.isArray(work.tags) &&
                    work.tags.map((tag, index) => (
                      <span key={index} className="tag">
                        #{tag}
                      </span>
                    ))}
                </Tags>
                <div>
                  {isLikedState ? (
                    <HeartIcon onClick={handleHeartIcon} fill="true" />
                  ) : (
                    <HeartIcon onClick={handleHeartIcon} />
                  )}
                </div>
                <p className="mt-4">{work.comment}</p>
                {/* <div className="mt-6">
                  <Button onClick={handlePartsClick}>View Parts</Button>
                  <Button
                    onClick={handleLikesClick}
                    disabled={likesCount === 0}
                  >
                    View Likes
                  </Button>
                </div> */}
              </Overlay>
            </div>
          )}
        </AnimatePresence>
      </Modal>
      <PartsPopup
        parts={partsList}
        isOpen={isPartsPopupOpen}
        onClose={() => setIsPartsPopupOpen(false)}
      />
      <LikesPopup
        isOpen={isLikesPopupOpen}
        onClose={() => setIsLikesPopupOpen(false)}
        likes={likeList}
      />
    </>
  );
};

export default WorkPopup;
