import React, {
  useState,
  useCallback,
  useMemo,
  useEffect,
  useRef,
} from "react";
import Autosuggest from "react-autosuggest";
import { useGallery } from "../../contexts/GalleryContext";
import { fetchLikeTags } from "../../api/apiClient";
import debounce from "lodash/debounce";

const AutoCompleteSearchBar = React.memo(({ setTag, defaultTags }) => {
  const { searchTag, updateSearchTag, removeSearchTag, updateMultiSearchTag } =
    useGallery();
  const [value, setValue] = useState("");
  const [suggestions, setSuggestions] = useState([]);
  const inputRef = useRef(null);
  const isComposingRef = useRef(false);

  useEffect(() => {
    if (defaultTags) updateMultiSearchTag(defaultTags);
  }, [defaultTags, updateMultiSearchTag]);

  useEffect(() => {
    if (setTag) {
      setTag(searchTag);
    }
  }, [searchTag, setTag]);

  const debouncedGetSuggestions = useMemo(
    () =>
      debounce((inputValue) => {
        fetchLikeTags(inputValue).then((x) => {
          setSuggestions(x.filter((e) => !searchTag.includes(e)));
        });
      }, 300),
    [searchTag]
  );

  useEffect(() => {
    if (value) {
      debouncedGetSuggestions(value);
    } else {
      setSuggestions([]);
    }

    return () => {
      debouncedGetSuggestions.cancel();
    };
  }, [value, debouncedGetSuggestions]);

  const onSuggestionsFetchRequested = useCallback(() => {
    // This is now handled by the useEffect
  }, []);

  const onSuggestionsClearRequested = useCallback(() => {
    setSuggestions([]);
  }, []);

  const addTag = useCallback(
    (newTag) => {
      if (newTag && !searchTag.includes(newTag)) {
        updateSearchTag(newTag);
        setValue("");
        inputRef.current.value = "";
      }
    },
    [searchTag, updateSearchTag]
  );

  const onSuggestionSelected = useCallback(
    (event, { suggestionValue }) => {
      addTag(suggestionValue);
    },
    [addTag]
  );

  const handleKeyDown = useCallback(
    (e) => {
      if (e.key === "Enter" && !isComposingRef.current) {
        e.preventDefault();
        const highlightedSuggestion = document.querySelector(
          ".react-autosuggest__suggestion--highlighted"
        );
        if (highlightedSuggestion) {
          addTag(highlightedSuggestion.textContent);
        } else {
          const currentInputValue = inputRef.current.value.trim();
          addTag(currentInputValue);
        }
      }
    },
    [addTag]
  );

  const handleCompositionStart = () => {
    isComposingRef.current = true;
  };

  const handleCompositionEnd = () => {
    isComposingRef.current = false;
  };

  const inputProps = useMemo(
    () => ({
      id: "search-input",
      name: "search-input",
      placeholder: "태그 추가 후 엔터 혹은 선택하세요",
      value,
      onChange: (_, { newValue }) => setValue(newValue),
      onKeyDown: handleKeyDown,
      ref: inputRef,
      onCompositionStart: handleCompositionStart,
      onCompositionEnd: handleCompositionEnd,
      className:
        "p-2  my-[10px] border-2 border-black-500 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-400 focus:border-transparent w-full",
    }),
    [value, handleKeyDown]
  );

  const renderInputComponent = useCallback((inputProps) => {
    const { ref, ...restInputProps } = inputProps;
    return <input ref={ref} {...restInputProps} />;
  }, []);

  const renderSuggestion = useCallback(
    (suggestion) => (
      <div className="p-3 hover:bg-blue-100 cursor-pointer">{suggestion}</div>
    ),
    []
  );

  return (
    <div className="mb-6">
      <div className="relative flex justify-center ">
        <Autosuggest
          suggestions={suggestions}
          onSuggestionsFetchRequested={onSuggestionsFetchRequested}
          onSuggestionsClearRequested={onSuggestionsClearRequested}
          onSuggestionSelected={onSuggestionSelected}
          getSuggestionValue={(suggestion) => suggestion}
          renderSuggestion={renderSuggestion}
          inputProps={inputProps}
          renderInputComponent={renderInputComponent}
          theme={{
            container: "relative z-50  w-full",
            suggestionsList: "list-none m-0 p-0",
            suggestion: "cursor-pointer",
          }}
        />
      </div>
      <div className="mt-4 flex flex-wrap">
        {searchTag?.map((tag) => (
          <span
            key={tag}
            className="inline-flex items-center px-3 py-1 rounded-full text-sm font-medium bg-blue-500 text-white mr-2 mb-2"
          >
            {tag}
            <button
              onClick={() => removeSearchTag(tag)}
              className="ml-2 focus:outline-none"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-4 w-4"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fillRule="evenodd"
                  d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                  clipRule="evenodd"
                />
              </svg>
            </button>
          </span>
        ))}
      </div>
    </div>
  );
});

export default AutoCompleteSearchBar;
