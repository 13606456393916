import React, { useCallback, useState } from "react";
import styled from "styled-components";
import PartsCreateUpdatePopup from "../Popup/PartsCreateUpdatePopup";
import * as api from "../../api/apiClient";
import LoadingModal from "../Common/LoadingModal";

const ItemContainer = styled.div`
  @apply bg-white rounded-lg shadow-md overflow-hidden cursor-pointer transition-transform duration-200 hover:scale-105;
`;

const Image = styled.img`
  @apply w-full h-48 object-cover;
`;

const PartsItem = ({ work, onImageLoad, parentKey }) => {
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [itemPopupInfo, setItemPopupInfo] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const fetchWorkInfo = useCallback(async () => {
    setLoading(true);
    setError(null);
    try {
      const data = await api.getPartsInfo(parentKey);
      setItemPopupInfo(data);
      setIsPopupOpen(true);
    } catch (err) {
      setError(err.message || "An error occurred while fetching works");
      console.error("Error fetching works:", err);
    } finally {
      setLoading(false);
    }
  }, [parentKey]);

  const handleClick = () => {
    fetchWorkInfo(parentKey);
  };

  const handleClose = () => {
    setIsPopupOpen(false);
  };

  if (loading) return <LoadingModal isOpen />;
  if (error) {
    return <div>error</div>;
  }
  return (
    <>
      <ItemContainer onClick={handleClick}>
        <Image src={work.imageUrl} alt={work.name} onLoad={onImageLoad} />
      </ItemContainer>
      {isPopupOpen && (
        <PartsCreateUpdatePopup
          work={itemPopupInfo}
          isOpen={isPopupOpen}
          onClose={handleClose}
        />
      )}
    </>
  );
};

export default PartsItem;
