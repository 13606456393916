import React from "react";
import { Helmet } from "react-helmet-async";
import { generateMetaTags } from "../utils/seoHelpers";

const TemplatesPage = () => {
  return (
    <div>
      <Helmet>
        {generateMetaTags(
          "DIY Templates - DIY KITTEN",
          "Browse and use our collection of DIY templates",
          "https://diygallery.com/images/templates-preview.jpg"
        )}
      </Helmet>
      <h1>DIY Templates</h1>
      {/* Add template browsing functionality here */}
    </div>
  );
};

export default TemplatesPage;
