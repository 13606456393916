import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { jwtDecode } from "jwt-decode"; // To decode JWT tokens
import { apiClient } from "../api/apiClient";

const OAuthCallback = () => {
  const navigate = useNavigate();

  // Function to extract query params
  const getQueryParams = (param) => {
    const urlParams = new URLSearchParams(window.location.search);
    return urlParams.get(param);
  };

  useEffect(() => {
    // Extract access token from the URL
    const accessToken = getQueryParams("accessToken");
    
    if (accessToken) {
      // Decode the access token to get the user information
      const decodedToken = jwtDecode(accessToken);

      // Check the role of the user
      const userRole = decodedToken.role;

      if (userRole === "ROLE_ANONYMOUS") {
        // If the user role is anonymous, redirect to the signup form
        localStorage.setItem("preAccessToken", accessToken);

        navigate("/signin");
      } else if (userRole === "ROLE_USER") {
        // Set the Authorization header for future requests
        apiClient.defaults.headers.common[
          "Authorization"
        ] = `Bearer ${accessToken}`;
        // Save the access token to local storage for signed-in users
        localStorage.setItem("accessToken", accessToken);

        // Retrieve the previous path before OAuth login from localStorage
        const preOauthPath = localStorage.getItem("preOauthPath") || "/";

        // Navigate the user back to the previous path or dashboard
        navigate(preOauthPath || "/");
        window.location.reload();
        // Optionally, clear the saved path from localStorage
        localStorage.removeItem("preOauthPath");
      }
    } else {
      // If no token, redirect to login or show an error
      navigate("/login");
    }
  }, [navigate]);

  return <div>Logging in, please wait...</div>;
};

export default OAuthCallback;
