import React from "react";
import ReactDOM from "react-dom";
import { Loader2 } from "lucide-react";

const LoadingModal = ({ isOpen, message = "Loading..." }) => {
  if (!isOpen) return null;

  return ReactDOM.createPortal(
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-[9999]">
      <div className="bg-white p-6 rounded-lg shadow-xl flex flex-col items-center">
        <Loader2 className="h-12 w-12 text-blue-500 animate-spin mb-4" />
        <p className="text-gray-700 text-lg font-medium">{message}</p>
      </div>
    </div>,
    document.body
  );
};

export default LoadingModal;
