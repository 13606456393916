// src/PreviewCard.jsx
import React from "react";
import "./ContentCard.css";

const PreviewCard = ({ imageSrc, isSingle }) => {
  return (
    <div
      className={`card-container ${
        isSingle ? "single-item-container" : "multiple-items-container"
      }`}
    >
      <div className="image-wrapper">
        <img src={imageSrc} alt="contentimg" className="content-image" />
        <div className="reflection"></div>
      </div>
    </div>
  );
};

export default PreviewCard;
