import React from "react";
import "./Toolbox.css";
import { FaCopy, FaTrash, FaLayerGroup } from "react-icons/fa"; // Import icons

const Toolbox = ({
  onCopy,
  onDelete,
  onBringForward,
  onSendBackward,
  isDisabled,
}) => {
  return (
    <div className="toolbox">
      <button onClick={onCopy} disabled={isDisabled} className="toolbox-btn">
        <FaCopy />
      </button>
      <button onClick={onDelete} disabled={isDisabled} className="toolbox-btn">
        <FaTrash />
      </button>
      <button
        onClick={onBringForward}
        disabled={isDisabled}
        className="toolbox-btn"
      >
        <FaLayerGroup />
      </button>
      <button
        onClick={onSendBackward}
        disabled={isDisabled}
        className="toolbox-btn"
      >
        <FaLayerGroup style={{ transform: "rotate(180deg)" }} />
      </button>
    </div>
  );
};

export default Toolbox;
