import React, { useState } from "react";
import { X } from "lucide-react";
import AutoCompleteSearchBar from "../../../components/Common/GalleryAutoCompleteSearchBar";

const StyledInputModal = ({
  isOpen,
  onClose,
  onSave,
  onTemporarySave,
  defaultTags,
}) => {
  const [tagInput, setTagInput] = useState(defaultTags);
  const [comment, setComment] = useState("");

  if (!isOpen) return null;

  const handleSave = () => {
    onSave({ tagInput, comment });
    onClose();
  };

  const handleTemporarySave = () => {
    onTemporarySave({ tagInput, comment });
    onClose();
  };
  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-[9999]">
      <div className="bg-white w-full max-w-md rounded-lg shadow-xl">
        <div className="flex justify-between items-center p-4 border-b">
          <h2 className="text-lg font-semibold">정보입력</h2>
          <button
            onClick={onClose}
            className="text-gray-500 hover:text-gray-700"
          >
            <X size={20} />
          </button>
        </div>
        <div className="p-4">
          <div className="mb-4">
            <label className="block text-sm font-medium text-gray-700 mb-1">
              태그 입력
            </label>
            <AutoCompleteSearchBar
              className="w-[50px]"
              setTag={setTagInput}
              defaultTags={defaultTags}
            />
          </div>

          <div className="mb-4">
            <label className="block text-sm font-medium text-gray-700 mb-1">
              코멘트 입력 (100글자 이내)
            </label>
            <textarea
              value={comment}
              onChange={(e) => setComment(e.target.value)}
              maxLength={100}
              placeholder="내용을 입력해 주세요"
              className="w-full p-2 border rounded-md h-24 resize-none"
            />
          </div>
          <div className="flex justify-end space-x-2">
            <button
              onClick={handleTemporarySave}
              className="px-4 py-2 bg-gray-800 text-white rounded-md hover:bg-gray-700"
            >
              임시저장
            </button>
            <button
              onClick={handleSave}
              className="px-4 py-2 bg-orange-500 text-white rounded-md hover:bg-orange-600"
            >
              미리보기
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StyledInputModal;
