import React, { useEffect } from "react";
import Masonry from "react-masonry-css";
import styled from "styled-components";
import PartsItem from "./PartsItem";
import { useParts } from "../../contexts/PartsContext";
import LoadingModal from "../Common/LoadingModal";

const PartsContainer = styled.div`
  @apply container mx-auto p-4;
`;

const StyledMasonry = styled(Masonry)`
  display: flex;
  margin-left: -30px; /* gutter size offset */
  width: auto;
`;

const StyledMasonryColumn = styled.div`
  padding-left: 30px; /* gutter size */
  background-clip: padding-box;
`;

const PartsGrid = () => {
  const { works, loading, error, fetchWorks } = useParts();

  useEffect(() => {
    if (!loading) {
      fetchWorks();
    }
  }, [loading, fetchWorks]);

  const breakpointColumnsObj = {
    default: 4,
    1100: 3,
    700: 2,
    500: 1,
  };

  if (error) {
    return <div>Error: {error}</div>;
  }

  if (loading) return <LoadingModal isOpen />;
  if (!works.length) return "noworks";
  return (
    <PartsContainer>
      <StyledMasonry
        breakpointCols={breakpointColumnsObj}
        className="my-masonry-grid"
        columnClassName="my-masonry-grid_column"
      >
        {works.map((work) => (
          <StyledMasonryColumn key={work.parentPartKey}>
            <PartsItem work={work} parentKey={work.parentPartKey} />
          </StyledMasonryColumn>
        ))}
      </StyledMasonry>
      {!loading && works.length === 0 && <div>마지막 페이지입니다.</div>}
    </PartsContainer>
  );
};

export default PartsGrid;
