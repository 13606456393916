import ProjectPage from "../../../components/Common/ProjectPage";

const BeadsCurtainPage = () => {
  const themes = [
    {
      name: "꽃무늬",
      description: "화려한 꽃 디자인의 비즈발을 만들어보세요.",
    },
    {
      name: "기하학적",
      description: "정교한 기하학적 패턴의 비즈발을 만들어보세요.",
    },
    {
      name: "캐릭터",
      description: "귀여운 캐릭터 디자인의 비즈발을 만들어보세요.",
    },
  ];

  const renderModalContent = (theme) => (
    <>
      <h2 className="text-2xl font-bold mb-4">{theme.name} 비즈발 패턴</h2>
      <p>이곳에 비즈발 {theme.name} 테마의 특별한 패턴 옵션을 추가하세요.</p>
      {/* 비즈발 특화 컨텐츠 */}
    </>
  );

  return (
    <ProjectPage
      title="비즈발"
      themes={themes}
      renderModalContent={renderModalContent}
    />
  );
};

export default BeadsCurtainPage;
