import React from "react";
import styled from "styled-components";
import Modal from "../Common/Modal";
import { motion, AnimatePresence } from "framer-motion";
import PartsAddPage from "../../pages/PartsAddPage";
import * as api from "../../api/apiClient";

const StyledModalContent = styled(motion.div)`
  position: relative;
`;

const CloseButton = styled.button`
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
`;

const PartsCreateUpdatePopup = ({ work, isOpen, onClose }) => {
  const handleDeleteButton = async (parentPartKey) => {
    try {
      await api.deleteParts(parentPartKey);
      onClose(); // Close the modal after successful deletion
      // You might want to trigger a refresh of the parts list here
    } catch (error) {
      console.error("Failed to delete part:", error);
      // Handle error (e.g., show an error message to the user)
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <AnimatePresence>
        {isOpen && (
          <StyledModalContent
            initial={{ opacity: 0, scale: 0.8 }}
            animate={{ opacity: 1, scale: 1 }}
            exit={{ opacity: 0, scale: 0.8 }}
            transition={{ duration: 0.3 }}
          >
            <CloseButton onClick={onClose}>&times;</CloseButton>
            {work && (
              <div onClick={() => handleDeleteButton(work.parentPartKey)}>
                삭제
              </div>
            )}
            <PartsAddPage parentKey={work?.parentPartKey} partsData={work} />
          </StyledModalContent>
        )}
      </AnimatePresence>
    </Modal>
  );
};

export default PartsCreateUpdatePopup;
