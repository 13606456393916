import React, { useEffect, useCallback, useState } from "react";
import Masonry from "react-masonry-css";
import styled from "styled-components";
import { useInView } from "react-intersection-observer";
import GalleryItem from "./GalleryItem";
import SortButtons from "../Common/SortButtons";
import { useGallery } from "../../contexts/GalleryContext";
import BoardTypeButtons from "./BoardTypeButtons";
import AutoCompleteSearchBar from "../Common/GalleryAutoCompleteSearchBar";
import { useLocation, useNavigate } from "react-router-dom";
import * as api from "../../api/apiClient";
import WorkPopup from "../Popup/WorkPopup";
import PreviewPopup from "../Popup/PreviewPopup";
import LoadingModal from "../Common/LoadingModal";

const GalleryContainer = styled.div`
  @apply container mx-auto p-4;
`;

const StyledMasonry = styled(Masonry)`
  display: flex;
  margin-left: -30px; /* gutter size offset */
  width: auto;
`;

const StyledMasonryColumn = styled.div`
  padding-left: 30px; /* gutter size */
  background-clip: padding-box;
`;

const LoadingOrEndMessage = styled.div`
  @apply text-center py-4 text-gray-600;
`;

const GalleryGrid = () => {
  const { works, loading, error, hasMore, fetchWorks } = useGallery();
  const [ref, inView] = useInView({
    threshold: 0,
    rootMargin: "200px",
  });

  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [itemPopupInfo, setItemPopupInfo] = useState(null);
  const [itemLoading, setItemLoading] = useState(false);
  const [itemError, setItemError] = useState(null);
  const location = useLocation();
  const navigate = useNavigate();
  const [itemKey, setItemKey] = useState(null);

  const fetchWorkInfo = useCallback(async (id) => {
    setItemLoading(true);
    setItemError(null);
    try {
      const data = await api.fetchWorkInfo(id);
      setItemPopupInfo(data);
      setIsPopupOpen(true);
    } catch (err) {
      setItemError(err.message || "An error occurred while fetching work info");
      console.error("Error fetching work info:", err);
    } finally {
      setItemLoading(false);
    }
  }, []);

  useEffect(() => {
    if (inView && hasMore && !loading) {
      fetchWorks();
    }
  }, [inView, hasMore, loading, fetchWorks]);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const idParam = params.get("id");

    if (idParam) {
      fetchWorkInfo(idParam);
      setItemKey(idParam);
    }
  }, [location, fetchWorkInfo]);

  const handleClose = () => {
    setItemPopupInfo(null);
    setIsPopupOpen(false);
    navigate(location.pathname, { replace: true });
  };

  const handleClickItem = (id) => {
    fetchWorkInfo(id);
    navigate(`${location.pathname}?id=${id}`, { replace: true });
  };

  const breakpointColumnsObj = {
    default: 4,
    1100: 3,
    700: 2,
    500: 1,
  };

  return (
    <>
      <GalleryContainer>
        <AutoCompleteSearchBar />
        <div className="flex justify-end  items-center">
          <SortButtons />
        </div>
        <div className="h-[1rem]" />
        <StyledMasonry
          breakpointCols={breakpointColumnsObj}
          className="my-masonry-grid"
          columnClassName="my-masonry-grid_column"
        >
          {works.map((work) => (
            <StyledMasonryColumn key={work.id}>
              <>
                <GalleryItem
                  work={work}
                  boardKey={work.id}
                  handleClickItem={handleClickItem}
                />
                <div className="h-[30px]" />
              </>
            </StyledMasonryColumn>
          ))}
        </StyledMasonry>
        {hasMore && (
          <div ref={ref} style={{ height: "20px", margin: "20px 0" }} />
        )}
        {loading && <LoadingModal isOpen />}
        {!hasMore && (
          <LoadingOrEndMessage>마지막 페이지입니다.</LoadingOrEndMessage>
        )}
        {!loading && works.length === 0 && <div></div>}
      </GalleryContainer>
      {isPopupOpen && itemPopupInfo && (
        <WorkPopup
          id={itemKey}
          work={{ ...itemPopupInfo }}
          isOpen={isPopupOpen}
          onClose={handleClose}
          state={itemError ? "error" : itemLoading ? "loading" : "stable"}
          isLiked={itemPopupInfo.isMyLike}
        />
      )}
    </>
  );
};

export default GalleryGrid;
