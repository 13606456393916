import React, { createContext, useContext, useState, useCallback } from "react";
import * as api from "../api/apiClient";
import LoadingModal from "../components/Common/LoadingModal";

const PartsContext = createContext();

export const useParts = () => useContext(PartsContext);

export const PartsProvider = ({ children }) => {
  const [works, setWorks] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [theme, setTheme] = useState("HALLOWEEN");
  const [itemType, setItemType] = useState("MORU");

  const fetchWorks = useCallback(async () => {
    if (loading) return <LoadingModal isOpen />;

    try {
      const data = await api.getAllParts();
      setWorks(data);
    } catch (err) {
      if (err.response) {
        // The request was made, and the server responded with a status code
        // that falls out of the range of 2xx
        console.error(
          "Server responded with a status other than 2xx:",
          err.response.status
        );
      } else if (err.request) {
        // The request was made, but no response was received
        // This could indicate a CORS issue or a network error
        console.error("No response received:", err.request);
        if (err.request.status === 0) {
          console.error("CORS error detected");
        }
      } else {
        // Something happened in setting up the request that triggered an Error
        console.error("Error in setting up the request:", err.message);
      }
      setError(err.message || "An error occurred while fetching works");
      console.error("Error fetching works:", err);
    } finally {
      setLoading(false);
    }
  }, [loading]);

  const value = {
    works,
    loading,
    error,
    theme,
    fetchWorks,
  };

  return (
    <PartsContext.Provider value={value}>{children}</PartsContext.Provider>
  );
};

export default PartsProvider;
