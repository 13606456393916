import React, { useState } from "react";
import { jwtDecode } from "jwt-decode";
import { Link, NavLink } from "react-router-dom";
import styled from "styled-components";
import { ChevronDown, ChevronUp, Menu, X } from "lucide-react";
import { s3Url } from "../../static";

const HeaderContainer = styled.header`
  width: 100%;
  z-index: 1000;
  font-family: "Montserrat", sans-serif;

  background: transparent;
`;

const Nav = styled.nav`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 1em;

  @media (max-width: 1080px) {
    justify-content: space-between;
  }
`;

const Logo = styled(Link)`
  font-weight: 700;
  font-size: 1.875em;

  text-transform: uppercase;
`;

const NavLinks = styled.div`
  display: flex;
  align-items: center;

  @media (max-width: 1080px) {
    display: ${(props) => (props.isOpen ? "flex" : "none")};
    flex-direction: column;
    position: absolute;
    top: 60px;
    left: 0;
    width: 100%;
    background: rgba(0, 0, 0, 0.9);
    padding: 1em;
    color: #fff;
  }
`;

const StyledNavLink = styled(NavLink)`
  padding: 0.75em 1.875em;

  font-size: 0.875em;
  text-transform: uppercase;
  transition: 0.1s;
  &:hover {
    color: #e88382;
    border-bottom: 0.1875em solid #fce38a;
  }
  &.active {
    border-bottom: 0.1875em solid #fce38a;
  }

  @media (max-width: 1080px) {
    padding: 1em 0;
    text-align: center;
    width: 100%;
  }
`;

const DropdownContainer = styled.div`
  position: relative;
  inline-block;
  text-left;
`;

const DropdownButton = styled.button`
  display: inline-flex;
  align-items: center;
  padding: 0.75em 1.875em;
  font-size: 1em;

  background-color: transparent;
  cursor: pointer;
  transition: all 0.3s;
  &:hover {
    color: #e88382;
  }

  @media (max-width: 1080px) {
    padding: 1em 0;
    text-align: center;
    width: 100%;
  }
`;

const DropdownMenu = styled.div`
  position: absolute;
  right: 0;
  margin-top: 0.5em;
  width: 12em;
  background: #222;
  border: 1px solid #f6eac5;
  border-radius: 0.25em;
  z-index: 10;

  @media (max-width: 1080px) {
    width: 100%;
    position: static;
    margin-top: 0;
  }
`;

const DropdownItem = styled(NavLink)`
  display: block;
  padding: 0.5em 1em;
  color: #f6eac5;
  text-align: left;
  text-decoration: none;
  transition: 0.3s;
  &:hover {
    background: #444;
  }

  @media (max-width: 1080px) {
    padding: 1em 0;
    text-align: center;
  }
`;

const HamburgerButton = styled.button`
  display: none;
  @media (max-width: 1080px) {
    display: block;
    background: transparent;
    border: none;

    font-size: 1.5em;
  }
`;

const Header = () => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const siteCookie = localStorage.getItem("accessToken");
  const isUserRole = siteCookie
    ? jwtDecode(siteCookie).role === "ROLE_USER"
    : false;

  const projects = [
    { name: "모루인형", link: "/projects/moru" },
    // { name: "보석십자수", link: "/projects/gem-stitch" },
    // { name: "비즈발", link: "/projects/beads-curtain" },
  ];

  return (
    <HeaderContainer>
      <Nav>
        <Logo to="/">
          <div className="flex justify-center items-center">
            <img
              src={`${s3Url}/IMG_0830.PNG`}
              alt="diy kitten"
              className="w-[40px] mt-[0px] block"
            />
            <img
              src={`${s3Url}/IMG_0826.PNG`}
              alt="diy kitten img"
              className="w-[200px] mt-[10px] block"
            />
          </div>
        </Logo>
        <HamburgerButton onClick={() => setIsMenuOpen(!isMenuOpen)}>
          {isMenuOpen ? <X /> : <Menu />}
        </HamburgerButton>
        <NavLinks isOpen={isMenuOpen}>
          <DropdownContainer>
            <DropdownButton onClick={() => setIsDropdownOpen(!isDropdownOpen)}>
              프로젝트
              {isDropdownOpen ? (
                <ChevronUp className="ml-2 h-5 w-5" />
              ) : (
                <ChevronDown className="ml-2 h-5 w-5" />
              )}
            </DropdownButton>
            {isDropdownOpen && (
              <DropdownMenu>
                {projects.map((project) => (
                  <DropdownItem
                    key={project.name}
                    to={project.link}
                    onClick={() => {
                      setIsDropdownOpen(false);
                      setIsMenuOpen(false);
                    }}
                  >
                    {project.name}
                  </DropdownItem>
                ))}
              </DropdownMenu>
            )}
          </DropdownContainer>
          {/* <StyledNavLink to="/goods" onClick={() => setIsMenuOpen(false)}>
            공식 굿즈
          </StyledNavLink> */}
          {/* <StyledNavLink to="/events" onClick={() => setIsMenuOpen(false)}>
            이벤트
          </StyledNavLink> */}
          <StyledNavLink
            onClick={() =>
              window.open(
                "https://docs.google.com/forms/d/1fplHxlNO9wvYFRBVyXB8AZve2L8a099h7OzBPSbp0bI",
                "_blank"
              )
            }
          >
            문의
          </StyledNavLink>
          {/* <StyledNavLink to="/business" onClick={() => setIsMenuOpen(false)}>
            비즈니스 문의
          </StyledNavLink> */}
          {/* <StyledNavLink
            to="/parts-management"
            onClick={() => setIsMenuOpen(false)}
          >
            파츠 관리
          </StyledNavLink> */}
          {/* <StyledNavLink to="/about" onClick={() => setIsMenuOpen(false)}>
            About
          </StyledNavLink> */}
          {!siteCookie || !isUserRole ? (
            <StyledNavLink
              to="/signin"
              onClick={() => {
                localStorage.removeItem("preAccessToken");
                setIsMenuOpen(false);
              }}
            >
              Login
            </StyledNavLink>
          ) : (
            <StyledNavLink to="/logout" onClick={() => setIsMenuOpen(false)}>
              Logout
            </StyledNavLink>
          )}

          <StyledNavLink to="/mypage" onClick={() => setIsMenuOpen(false)}>
            mypage
          </StyledNavLink>
        </NavLinks>
      </Nav>
    </HeaderContainer>
  );
};

export default Header;
