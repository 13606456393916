import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { HelmetProvider } from "react-helmet-async";
import { GalleryProvider } from "./contexts/GalleryContext";
import { MyPageProvider } from "./contexts/MyPageContext";
import { ScaleProvider } from "./contexts/ScaleContext";
import PartsProvider from "./contexts/PartsContext";

import Header from "./components/Header/Header";
import HomePage from "./pages/HomePage";
import TemplatesPage from "./pages/TemplatesPage";
import GoodsPage from "./pages/GoodsPage";
import MyPage from "./pages/MyPage";
import EventsPage from "./pages/EventsPage";
import AboutPage from "./pages/AboutPage";
import SignupPage from "./pages/SignupPage";
import SignupCompletePage from "./pages/SignupCompletePage";
import OAuthCallback from "./pages/OauthCallback";
import LogoutPage from "./pages/LogoutPage";
import MoruPage from "./pages/projects/moru";
import GemStitchPage from "./pages/projects/gem-stitch";
import BeadsCurtainPage from "./pages/projects/beads-curtain";
import PartsManagementPage from "./pages/PartsManagementPage";

import GlobalStyles from "./styles/globalStyles";
import { apiClient } from "./api/apiClient";
import ProfileProvider from "./contexts/ProfileContext";
import Footer from "./components/Footer";

function App() {
  // For demonstration purposes, using a hardcoded token
  // In a real application, you should use a more secure method to store and retrieve tokens
  const token = localStorage.getItem("accessToken");

  if (token) {
    apiClient.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  }
  return (
    <HelmetProvider>
      <GlobalStyles />
      <Router>
        <div className="flex flex-col min-h-screen">
          <Header />
          <main className="flex-grow">
            <Routes>
              <Route path="/" element={<HomePage />} />
              <Route path="/signup" element={<OAuthCallback />} />
              <Route path="/signin" element={<SignupPage />} />
              <Route path="/signup-complete" element={<SignupCompletePage />} />
              <Route path="/logout" element={<LogoutPage />} />

              <Route path="/templates" element={<TemplatesPage />} />
              <Route path="/goods" element={<GoodsPage />} />
              {/* <Route path="/events" element={<EventsPage />} /> */}
              {/* <Route path="/about" element={<AboutPage />} /> */}

              <Route
                path="/mypage"
                element={
                  <GalleryProvider>
                    <ProfileProvider>
                      <ScaleProvider>
                        <MyPageProvider>
                          <MyPage />
                        </MyPageProvider>
                      </ScaleProvider>
                    </ProfileProvider>
                  </GalleryProvider>
                }
              />

              <Route
                path="/projects/moru"
                element={
                  <ProfileProvider>
                    <GalleryProvider>
                      <ScaleProvider>
                        <MoruPage />
                      </ScaleProvider>
                    </GalleryProvider>
                  </ProfileProvider>
                }
              />
              <Route path="/projects/gem-stitch" element={<GemStitchPage />} />
              <Route
                path="/projects/beads-curtain"
                element={<BeadsCurtainPage />}
              />

              <Route
                path="/parts-management"
                element={
                  <PartsProvider>
                    <PartsManagementPage />
                  </PartsProvider>
                }
              />
            </Routes>
          </main>
        </div>
      </Router>
      <Footer />
    </HelmetProvider>
  );
}

export default App;
