import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { FaEdit, FaTrashAlt, FaTimes } from "react-icons/fa";

const ContextMenuContainer = styled.div`
  position: absolute;
  padding: 5px;
  background-color: white;
  border: 1px solid #ccc;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  border-radius: 6px;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  min-width: 150px;
`;

const MenuItem = styled.button`
  display: flex;
  align-items: center;
  padding: 8px;
  font-size: 0.9rem;
  color: #333;
  background: none;
  border: none;
  cursor: pointer;
  width: 100%;
  text-align: left;
  gap: 8px;

  &:hover {
    background-color: #f5f5f5;
  }

  &:focus {
    outline: none;
    background-color: #ebebeb;
  }
`;

const MyArtOptions = ({ onDelete, onUpdate, onClose, position }) => {
  const menuRef = useRef(null); // Reference to the context menu
  const [menuPosition, setMenuPosition] = useState({ top: 0, left: 0 });

  useEffect(() => {
    if (position) {
      const { top, left } = position;
      const menu = menuRef.current;
      if (menu) {
        const { innerWidth, innerHeight } = window;
        const menuRect = menu.getBoundingClientRect();

        let adjustedTop = top;
        let adjustedLeft = left;

        // Adjust position if it would overflow the window boundaries
        if (adjustedLeft + menuRect.width > innerWidth) {
          adjustedLeft = innerWidth - menuRect.width - 10;
        }
        if (adjustedTop + menuRect.height > innerHeight) {
          adjustedTop = top - menuRect.height;
        }

        setMenuPosition({ top: adjustedTop, left: adjustedLeft });
      }
    }
  }, [position]);

  return (
    <ContextMenuContainer
      ref={menuRef}
      style={{ top: `${menuPosition.top}px`, left: `${menuPosition.left}px` }}
    >
      <MenuItem onClick={onUpdate}>
        <FaEdit /> 수정
      </MenuItem>
      <MenuItem onClick={onDelete}>
        <FaTrashAlt /> 삭제
      </MenuItem>
      <MenuItem onClick={onClose}>
        <FaTimes /> 취소
      </MenuItem>
    </ContextMenuContainer>
  );
};

export default MyArtOptions;
