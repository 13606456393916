// MoruCustomizer.jsx

import React, { useState, useRef, useEffect, useCallback } from "react";
import { Stage, Layer, Line, Image, Transformer } from "react-konva";
import useImage from "use-image";
import Toolbox from "../../../components/Common/Toolbox";
import { CANVAS_SIZE, GRID_SIZE, mmToPx, getCanvasDimensions } from "./config";
import { useScale } from "../../../contexts/ScaleContext";
import styled from "styled-components";
import ReactDOM from "react-dom";
import { getMoruResult } from "../../../api/apiClient";
import Modal from "../../../components/Common/Modal";
import LoadingModal from "../../../components/Common/LoadingModal";
import InputBoardInfoModal from "./InputBoardInfoModal";
// URLImage component
const URLImage = ({
  image,
  isSelected,
  onSelect,
  onChange,
  isFront,
  scale,
}) => {
  const [img] = useImage(image.partsImageUrl);
  const imageRef = useRef();
  const transformerRef = useRef();

  const isCurrentSide = image.side === (isFront ? "front" : "back");

  useEffect(() => {
    if (isSelected && isCurrentSide) {
      transformerRef.current.nodes([imageRef.current]);
      transformerRef.current.getLayer().batchDraw();
    }
  }, [isSelected, isCurrentSide]);

  const displayedWidth = image.width;
  const displayedHeight = image.height;

  // Apply flipping for mirrored images
  const scaleX = image.isMirrored ? -1 : 1;
  const offsetX = displayedWidth / 2;
  const offsetY = displayedHeight / 2;

  return (
    <>
      <Image
        image={img}
        x={image.x}
        y={image.y}
        width={displayedWidth}
        height={displayedHeight}
        draggable={isCurrentSide && image.draggable !== false}
        rotation={image.rotation || 0} // Ensure rotation is not inverted here
        ref={imageRef}
        onClick={isCurrentSide ? onSelect : undefined}
        onTap={isCurrentSide ? onSelect : undefined}
        onDragEnd={
          isCurrentSide
            ? (e) => {
                const node = imageRef.current;
                onChange({
                  ...image,
                  x: node.x(),
                  y: node.y(),
                });
              }
            : undefined
        }
        onTransformEnd={
          isCurrentSide
            ? (e) => {
                const node = imageRef.current;
                onChange({
                  ...image,
                  x: node.x(),
                  y: node.y(),
                  width: node.width() * node.scaleX(),
                  height: node.height() * node.scaleY(),
                  rotation: node.rotation(), // No inversion here
                  scaleX: 1,
                  scaleY: 1,
                });
                node.scaleX(1);
                node.scaleY(1);
              }
            : undefined
        }
        offsetX={offsetX}
        offsetY={offsetY}
        scaleX={scaleX}
        listening={isCurrentSide}
        opacity={isCurrentSide ? 1 : 0.7}
      />
      {isSelected && isCurrentSide && <Transformer ref={transformerRef} />}
    </>
  );
};

// MoruImage component
const MoruImage = ({ item }) => {
  const [img] = useImage(item.partsImageUrl);

  return (
    <Image
      key={item.id}
      image={img}
      x={item.x}
      y={item.y}
      width={item.width}
      height={item.height}
      draggable={false}
      listening={false}
    />
  );
};

const drawGrid = (scale) => {
  const lines = [];
  const scaledCanvasSize = CANVAS_SIZE * scale;
  const scaledGridSize = GRID_SIZE * scale;

  const numLines = Math.ceil(scaledCanvasSize / scaledGridSize);

  for (let i = 0; i <= numLines; i++) {
    const position = i * scaledGridSize;
    lines.push(
      <Line
        key={`v-${i}`}
        points={[position, 0, position, scaledCanvasSize]}
        stroke="lightgray"
        strokeWidth={1}
      />
    );
    lines.push(
      <Line
        key={`h-${i}`}
        points={[0, position, scaledCanvasSize, position]}
        stroke="lightgray"
        strokeWidth={1}
      />
    );
  }
  return lines;
};

// Styled Components
const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  width: 100%;

  @media (min-width: 800px) {
    flex-direction: row;
  }
`;

const PartsSelector = styled.div`
  width: 100%;
  @media (min-width: 800px) {
    width: 500px;
    height: 60vh;
    overflow-y: scroll;
  }
`;

const PartsTabs = styled.div`
  display: flex;
  flex-wrap: wrap;
  overflow-x: auto;
  margin-bottom: 10px;

  button {
    flex: 0 0 auto;
    padding: 5px 10px;
    margin-right: 5px;
    background-color: #f0f0f0;
    border: none;
    cursor: pointer;

    &.selected {
      background-color: #d0d0d0;
    }
  }
`;

const PartsList = styled.div`
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
  max-width: 100%;
  margin-bottom: 20px;

  @media (min-width: 800px) {
    flex-wrap: wrap;
    overflow-x: visible;
  }
`;

const PartItem = styled.div`
  flex: 0 0 auto;
  margin-right: 10px;
  text-align: center;
  cursor: pointer;

  img {
    width: 80px;
    height: 80px;
    object-fit: contain;
  }

  span {
    display: block;
    margin-top: 5px;
  }

  @media (min-width: 800px) {
    margin: 5px;
  }
`;

const BaseCharacterSelector = styled.div`
  margin-bottom: 20px;
  display: block;
  select {
    padding: 5px 10px;
    font-size: 16px;
  }
`;

const CanvasContainer = styled.div`
  margin-bottom: 20px;
`;

const ToggleSwitch = styled.div`
  .switch {
    position: relative;
    display: inline-block;
    width: 100px;
    height: 34px;
  }

  .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    transition: 0.4s;
    border-radius: 30px;
    background-color: #2196f3;

    .slider-knob {
      position: absolute;
      content: "";
      height: 26px;
      width: 50px;
      left: 4px;
      bottom: 4px;
      background-color: white;
      transition: 0.4s;
      border-radius: 30px;
    }

    .label-front,
    .label-back {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      font-size: 12px;
      color: white;
    }

    .label-front {
      left: 10px;
    }

    .label-back {
      right: 10px;
    }
  }

  input:checked + .slider {
    background-color: #2196f3;
  }

  input:checked + .slider .slider-knob {
    transform: translateX(40px);
  }
`;

const ContextMenu = styled.div`
  position: absolute;
  background-color: white;
  border: 1px solid #ccc;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.2);
  z-index: 1000;
`;

const ContextMenuItem = styled.div`
  padding: 5px;
  cursor: pointer;
  display: flex;
  align-items: center;

  img {
    width: 30px;
    margin-right: 10px;
  }

  span {
    margin-right: 10px;
  }

  &:hover {
    background-color: #f0f0f0;
  }
`;

const MoruCustomizer = ({
  partsData,
  moruData,
  artInfoData,
  boardKey,
  handlePreviewButton,
  onClose,
  handleTemporarySaveButton,
}) => {
  const idCounterRef = useRef(0);
  const stageRef = useRef();
  const containerRef = useRef();
  const { scale, setScale } = useScale();
  const [selectedTab, setSelectedTab] = useState(partsData?.[0]?.themePosition);
  const [isFront, setIsFront] = useState(true);
  const [contextMenu, setContextMenu] = useState(null);
  const [selectedImageId, setSelectedImageId] = useState(null);

  const [frontCanvasItems, setFrontCanvasItems] = useState([]);
  const [backCanvasItems, setBackCanvasItems] = useState([]);

  const [selectedBaseCharacter, setSelectedBaseCharacter] = useState([]);
  const [generatedFrontImageUrl, setGeneratedFrontImageUrl] = useState("");
  const [generatedBackImageUrl, setGeneratedBackImageUrl] = useState("");

  const gridLayerRef = useRef();
  const itemLayerRef = useRef();
  const [loading, setLoading] = useState(false);
  // Modal state
  const [isModalOpen, setIsModalOpen] = useState(false);

  // For modal inputs
  const [tags, setTags] = useState([]);
  const [comment, setComment] = useState("");

  // Use ResizeObserver to update container width
  useEffect(() => {
    const container = containerRef.current;
    if (!container) return;
    const updateScale = () => {
      const width = container?.offsetWidth;
      if (width < CANVAS_SIZE) {
        const newScale = width / CANVAS_SIZE;
        setScale(newScale);
      } else {
        setScale(1);
      }
    };
    updateScale();

    const observer = new ResizeObserver(() => {
      updateScale();
    });
    observer.observe(container);
    return () => {
      observer.unobserve(container);
    };
  }, [setScale]);

  const loadMoru = useCallback(() => {
    const { width, height } = getCanvasDimensions(scale);
    const centerX = width / 2;
    const centerY = height - mmToPx(40, scale);
    const characterWidth = mmToPx(selectedBaseCharacter.width, scale);
    const characterHeight = mmToPx(selectedBaseCharacter.height, scale);

    const baseImageProps = (side) => ({
      id: `moru-image-${side}`,
      type: "moru",
      side,
      partsImageUrl:
        side === "front"
          ? selectedBaseCharacter.frontImgUrl
          : selectedBaseCharacter.backImgUrl,
      x: centerX - characterWidth / 2,
      y: centerY - characterHeight / 2,
      width: characterWidth,
      height: characterHeight,
      draggable: false,
      zIndex: 0,
    });

    const updateBaseImage = (items, side) => {
      return items?.map((item) =>
        item.type === "moru" && item.side === side
          ? {
              ...item,
              partsImageUrl:
                side === "front"
                  ? selectedBaseCharacter.frontImgUrl
                  : selectedBaseCharacter.backImgUrl,
              width: characterWidth,
              height: characterHeight,
              x: centerX - characterWidth / 2,
              y: centerY - characterHeight / 2,
            }
          : item
      );
    };

    setFrontCanvasItems((prevItems) => {
      if (prevItems?.length) {
        return updateBaseImage(prevItems, "front");
      } else {
        return [baseImageProps("front")];
      }
    });

    setBackCanvasItems((prevItems) => {
      if (prevItems?.length) {
        return updateBaseImage(prevItems, "back");
      } else {
        return [baseImageProps("back")];
      }
    });
  }, [scale, selectedBaseCharacter]);

  useEffect(() => {
    if (boardKey && artInfoData && moruData) {
      const baseCharacter = moruData.find(
        (character) => character.itemKey === artInfoData.itemKey
      );

      if (baseCharacter) {
        setSelectedBaseCharacter(baseCharacter);
      } else {
        // If not found, set to default
        setSelectedBaseCharacter(moruData[0]);
      }
    } else if (moruData) {
      // No artInfoData, set to default
      setSelectedBaseCharacter(moruData[0]);
    }
  }, [artInfoData, moruData, boardKey]);

  useEffect(() => {
    loadMoru();
  }, [loadMoru]);

  useEffect(() => {
    if (boardKey && artInfoData && selectedBaseCharacter) {
      // Initialize canvas items from artInfoData.partInfos
      const frontItems = [];
      const backItems = [];

      const { width, height } = getCanvasDimensions(scale);
      const centerX = width / 2;
      const centerY = height - mmToPx(40, scale);

      const characterWidth = mmToPx(selectedBaseCharacter.width, scale);
      const characterHeight = mmToPx(selectedBaseCharacter.height, scale);

      const baseImageProps = (side) => ({
        id: `moru-image-${side}`,
        type: "moru",
        side,
        partsImageUrl:
          side === "front"
            ? selectedBaseCharacter.frontImgUrl
            : selectedBaseCharacter.backImgUrl,
        x: centerX - characterWidth / 2,
        y: centerY - characterHeight / 2,
        width: characterWidth,
        height: characterHeight,
        draggable: false,
        zIndex: 0,
      });

      frontItems.push(baseImageProps("front"));
      backItems.push(baseImageProps("back"));

      let idCounter = idCounterRef.current;

      artInfoData.partInfos.forEach((partInfo) => {
        const item = {
          id: idCounter++, // Assign a unique ID
          type: "part",
          side: partInfo.side.toLowerCase(), // Convert to 'front' or 'back'
          partsKey: partInfo.partKey,
          partsImageUrl: partInfo.partsImgUrl,
          x: partInfo.corX,
          y: partInfo.corY,
          rotation: partInfo.rotation || 0,
          width: partInfo.customWidth,
          height: partInfo.customHeight,
          zIndex: partInfo.corZ,
        };
        if (item.side === "front") {
          frontItems.push(item);
        } else {
          backItems.push(item);
        }
      });

      setFrontCanvasItems(frontItems);
      setBackCanvasItems(backItems);

      idCounterRef.current = idCounter;
    } else if (!artInfoData && selectedBaseCharacter) {
      // No artInfoData, initialize default canvas items
      const { width, height } = getCanvasDimensions(scale);
      const centerX = width / 2;
      const centerY = height - mmToPx(40, scale);

      const characterWidth = mmToPx(selectedBaseCharacter.width, scale);
      const characterHeight = mmToPx(selectedBaseCharacter.height, scale);

      const baseImageProps = (side) => ({
        id: `moru-image-${side}`,
        type: "moru",
        side,
        partsImageUrl:
          side === "front"
            ? selectedBaseCharacter.frontImgUrl
            : selectedBaseCharacter.backImgUrl,
        x: centerX - characterWidth / 2,
        y: centerY - characterHeight / 2,
        width: characterWidth,
        height: characterHeight,
        draggable: false,
        zIndex: 0,
      });

      setFrontCanvasItems([baseImageProps("front")]);
      setBackCanvasItems([baseImageProps("back")]);
    }
  }, [artInfoData, selectedBaseCharacter, scale, boardKey]);

  const handleBaseCharacterChange = (character) => {
    setSelectedBaseCharacter(character);
    updateMoruImages(character);
  };

  const updateMoruImages = (character) => {
    const updatedFront = frontCanvasItems.map((item) =>
      item.id === "moru-image-front"
        ? {
            ...item,
            partsImageUrl: character.frontImgUrl,
            width: mmToPx(character.width, scale),
            height: mmToPx(character.height, scale),
            x: mmToPx(CANVAS_SIZE / 2 - character.width / 2, scale),
            y: mmToPx(CANVAS_SIZE / 2 - character.height / 2, scale),
          }
        : item
    );

    const updatedBack = backCanvasItems.map((item) =>
      item.id === "moru-image-back"
        ? {
            ...item,
            partsImageUrl: character.backImgUrl,
            width: mmToPx(character.width, scale),
            height: mmToPx(character.height, scale),
            x: mmToPx(CANVAS_SIZE / 2 - character.width / 2, scale),
            y: mmToPx(CANVAS_SIZE / 2 - character.height / 2, scale),
          }
        : item
    );

    setFrontCanvasItems(updatedFront);
    setBackCanvasItems(updatedBack);
  };

  // Handle copying a selected part
  const handleCopy = () => {
    if (selectedImageId == null) return;

    const currentItems = isFront ? frontCanvasItems : backCanvasItems;
    const setItems = isFront ? setFrontCanvasItems : setBackCanvasItems;

    const selectedItem = currentItems.find(
      (item) => item.id === selectedImageId
    );

    if (selectedItem && selectedItem.type === "part") {
      const newItem = {
        ...selectedItem,
        id: idCounterRef.current++, // Assign new unique ID
        x: selectedItem.x + mmToPx(1, scale), // Slight offset (1mm)
        y: selectedItem.y + mmToPx(1, scale),
      };

      setItems((prevItems) => [...prevItems, newItem]);
      setSelectedImageId(newItem.id); // Select the new item
    }
  };

  // Handle deleting a selected part
  const handleDelete = () => {
    if (selectedImageId == null) return;

    const setItems = isFront ? setFrontCanvasItems : setBackCanvasItems;

    setItems((prevItems) =>
      prevItems.filter((item) => item.id !== selectedImageId)
    );

    setSelectedImageId(null); // Deselect
  };

  // Handle bringing a selected part forward
  const handleBringForward = () => {
    if (selectedImageId == null) return;

    const setItems = isFront ? setFrontCanvasItems : setBackCanvasItems;

    setItems((prevItems) => {
      const items = [...prevItems];
      const index = items.findIndex((item) => item.id === selectedImageId);
      if (index < 0) return prevItems;

      // Increase zIndex
      items[index].zIndex += 1;

      return items;
    });
  };

  const handleSendBackward = () => {
    if (selectedImageId == null) return;

    const setItems = isFront ? setFrontCanvasItems : setBackCanvasItems;

    setItems((prevItems) =>
      prevItems.map((item) => {
        if (item.id === selectedImageId) {
          return { ...item, zIndex: Math.max(1, item.zIndex - 1) };
        }
        return item;
      })
    );

    setSelectedImageId(null); // Optionally deselect after sending backward
  };

  const getMaxZIndexForSide = (side) => {
    const items = side === "front" ? frontCanvasItems : backCanvasItems;
    const zIndices = items.map((item) => item.zIndex);
    return Math.max(...zIndices, 0);
  };

  const getCanvasItemsToRender = () => {
    const currentItems = isFront ? frontCanvasItems : backCanvasItems;
    const oppositeItems = isFront ? backCanvasItems : frontCanvasItems;

    // Filter out the moru image from oppositeItems
    const oppositeItemsWithoutMoru = oppositeItems.filter(
      (item) => item.type !== "moru"
    );

    // Adjust opposite items
    const adjustedOppositeItems = oppositeItemsWithoutMoru.map((item) => {
      const canvasWidth = getCanvasDimensions(scale).width;

      // Adjust x coordinate by flipping it around the canvas width
      const mirroredX = canvasWidth - item.x;

      // Rotation inversion should be handled server-side, so keep as is
      // Remove rotation inversion here
      // const mirroredRotation = -item.rotation;

      // Reverse zIndex
      const maxZIndex = getMaxZIndexForSide(
        item.side === "front" ? "back" : "front"
      );
      const adjustedZIndex = maxZIndex - item.zIndex + 1;

      return {
        ...item,
        x: mirroredX,
        rotation: -item.rotation,
        // rotation: mirroredRotation, // Remove rotation inversion
        zIndex: adjustedZIndex,
        isMirrored: true,
      };
    });

    // Combine both current and adjusted opposite items
    const combinedItems = [...adjustedOppositeItems, ...currentItems];

    // Sort items so that opposite side items are rendered first
    combinedItems.sort((a, b) => {
      if (a.side !== b.side) {
        // Opposite side items come first
        return a.side === (isFront ? "front" : "back") ? 1 : -1;
      }
      // Both items are from the same side, sort by zIndex
      return a.zIndex - b.zIndex;
    });

    return combinedItems;
  };

  const handlePartClick = (e, part) => {
    e.stopPropagation();
    if (part.childData.length) {
      const menuWidth = 200; // Adjust as needed
      const menuHeight = part.childData.length * 50; // Adjust as needed

      let mouseX = e.pageX;
      let mouseY = e.pageY;

      if (mouseX + menuWidth > window.innerWidth) {
        mouseX -= menuWidth;
      }

      if (mouseY + menuHeight > window.innerHeight) {
        mouseY -= menuHeight;
      }

      setContextMenu({
        mouseX,
        mouseY,
        part: part,
      });
      return;
    }
    handlePartSelect(part);
  };

  // Close context menu when clicking outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (contextMenu) {
        setContextMenu(null);
      }
    };

    window.addEventListener("click", handleClickOutside);

    return () => {
      window.removeEventListener("click", handleClickOutside);
    };
  }, [contextMenu]);

  const handlePartSelect = (childPart) => {
    addPartToCanvas(childPart);
    setContextMenu(null); // Close dropdown after selection
  };

  const addPartToCanvas = (part) => {
    const { width, height } = getCanvasDimensions(scale);
    const centerX = width / 2;
    const centerY = height / 2;
    const newId = idCounterRef.current++;
    const currentItems = isFront ? frontCanvasItems : backCanvasItems;

    // Find the maximum zIndex among current side's items
    const maxZIndex = currentItems.reduce(
      (max, item) => (item.zIndex > max ? item.zIndex : max),
      0 // Start from 0, the zIndex of the moru base image
    );

    const newPart = {
      ...part,
      partsKey: part.partsKey,
      x: centerX,
      y: centerY,
      rotation: 0,
      id: newId,
      type: "part",
      side: isFront ? "front" : "back",
      width: mmToPx(part.width, scale),
      height: mmToPx(part.height, scale),
      zIndex: maxZIndex + 1, // Ensure the part is above the moru image
    };

    if (isFront) {
      setFrontCanvasItems((prevItems) => [...prevItems, newPart]);
    } else {
      setBackCanvasItems((prevItems) => [...prevItems, newPart]);
    }
  };

  const handleImageChange = (newProps) => {
    if (newProps.side === "front") {
      setFrontCanvasItems((prevItems) =>
        prevItems.map((item) => (item.id === newProps.id ? newProps : item))
      );
    } else {
      setBackCanvasItems((prevItems) =>
        prevItems.map((item) => (item.id === newProps.id ? newProps : item))
      );
    }
  };

  const closeContextMenu = () => setContextMenu(null);

  const handleStageMouseDown = (e) => {
    // Clicked on an empty area - deselect any selected image
    const clickedOnEmpty = e.target === e.target.getStage();
    if (clickedOnEmpty) {
      setSelectedImageId(null);
    }
  };

  const mirrorPartsForOppositeSide = (parts, originalSide) => {
    const canvasWidth = getCanvasDimensions(scale).width;

    // Get the maximum z-index value
    const maxZIndex = Math.max(...parts.map((part) => part.corZ), 0);

    // Map to create mirrored parts with adjusted x coordinate and zIndex
    const mirroredParts = parts.map((part) => {
      // Adjust x coordinate by mirroring it around the canvas width
      const mirroredX = canvasWidth - part.corX;

      // Reverse zIndex
      const adjustedZIndex = maxZIndex - part.corZ + 1;

      return {
        ...part,
        corX: mirroredX,
        corZ: adjustedZIndex,
        rotation: part.rotation, // Remove rotation inversion; server handles it
        side: originalSide === "front" ? "BACK" : "FRONT", // Set side to opposite
        // Ensure customWidth and customHeight are included
        customWidth: part.customWidth,
        customHeight: part.customHeight,
        // Do not alter rotation here; it's handled in the server
      };
    });

    return mirroredParts;
  };

  const handleDoneClick = async () => {
    try {
      // Prepare payloads for both sides
      const canvasDimensions = getCanvasDimensions(scale);

      // Collect parts for front and back
      const frontParts = collectPartInfos("front");
      const backParts = collectPartInfos("back");

      // Create mirrored versions for opposite sides
      const mirroredBackPartsForFront = mirrorPartsForOppositeSide(
        backParts,
        "back"
      ); // Mirror back parts for front
      const mirroredFrontPartsForBack = mirrorPartsForOppositeSide(
        frontParts,
        "front"
      ); // Mirror front parts for back

      const frontPayload = {
        width: canvasDimensions.width,
        height: canvasDimensions.height,
        baseImageUrl: selectedBaseCharacter.frontImgUrl,
        baseImageWidth: Math.round(mmToPx(selectedBaseCharacter.width, scale)),
        baseImageHeight: Math.round(
          mmToPx(selectedBaseCharacter.height, scale)
        ),
        baseImageX: Math.round(
          (canvasDimensions.width -
            mmToPx(selectedBaseCharacter.width, scale)) /
            2
        ),
        baseImageY: Math.round(
          canvasDimensions.height -
            mmToPx(40, scale) -
            mmToPx(selectedBaseCharacter.height, scale) / 2
        ),
        partInfos: frontParts,
        oppositePartInfos: mirroredBackPartsForFront, // Direct assignment
        side: "front",
      };

      const backPayload = {
        width: canvasDimensions.width,
        height: canvasDimensions.height,
        baseImageUrl: selectedBaseCharacter.backImgUrl,
        baseImageWidth: Math.round(mmToPx(selectedBaseCharacter.width, scale)),
        baseImageHeight: Math.round(
          mmToPx(selectedBaseCharacter.height, scale)
        ),
        baseImageX: Math.round(
          (canvasDimensions.width -
            mmToPx(selectedBaseCharacter.width, scale)) /
            2
        ),
        baseImageY: Math.round(
          canvasDimensions.height -
            mmToPx(40, scale) -
            mmToPx(selectedBaseCharacter.height, scale) / 2
        ),
        partInfos: backParts,
        oppositePartInfos: mirroredFrontPartsForBack, // Direct assignment
        side: "back",
      };

      // Log payloads for debugging
      setLoading(true);
      // Send requests to generate images for both sides
      const [frontResponse, backResponse] = await Promise.all([
        getMoruResult(frontPayload),
        getMoruResult(backPayload),
      ]);
      // Set the generated image URLs
      setGeneratedFrontImageUrl(frontResponse);
      setGeneratedBackImageUrl(backResponse);
      // Open the modal to input tags and comment
      setLoading(false);
      setIsModalOpen(true);
    } catch (error) {
      console.error("Error generating images:", error);
      // Handle error (e.g., show notification to the user)
      alert(`Failed to generate the image: ${error.message}`);
    }
  };

  const collectPartInfos = (side = isFront ? "front" : "back") => {
    const items = side === "front" ? frontCanvasItems : backCanvasItems;
    const parts = items
      .filter((item) => item.type === "part")
      .map((item) => ({
        partKey: item.partsKey,
        partImageUrl: item.partsImageUrl,
        corX: Math.round(item.x),
        corY: Math.round(item.y),
        corZ: item.zIndex,
        rotation: item.rotation || 0, // **Removed inversion here**
        side: side.toUpperCase(), // 'FRONT' or 'BACK'
        customWidth: Math.round(item.width),
        customHeight: Math.round(item.height),
      }));
    return parts;
  };

  const handleOpenPreviewModal = ({ tagInput, comment }) => {
    // Prepare the payload
    const payload = {
      itemKey: selectedBaseCharacter.itemKey, // Assuming itemKey is 1 for Moru doll
      width: 100, // Width in mm
      height: 100, // Height in mm
      frontImgUrl: generatedFrontImageUrl,
      backImgUrl: generatedBackImageUrl,
      tags: tagInput,
      comment,
      isPost: true,
      colorHexCode: "#FFFFFF", // Adjust as needed
      partInfos: collectPartInfos("front").concat(collectPartInfos("back")),
    };
    handlePreviewButton(payload);
  };
  const handleTemporarySave = ({ tagInput, comment }) => {
    // Prepare the payload
    const payload = {
      itemKey: selectedBaseCharacter.itemKey, // Assuming itemKey is 1 for Moru doll
      width: 100, // Width in mm
      height: 100, // Height in mm
      frontImgUrl: generatedFrontImageUrl,
      backImgUrl: generatedBackImageUrl,
      tags: tagInput,
      comment,
      isPost: true,
      colorHexCode: "#FFFFFF", // Adjust as needed
      partInfos: collectPartInfos("front").concat(collectPartInfos("back")),
    };
    handleTemporarySaveButton({ ...payload, isPost: false });
  };

  const positionMap = {
    HEAD: "머리",
    EYE: "눈",
    MOUTH: "입",
    NECK: "목",
    WING: "날개",
    ORNAMENT: "장식품",
    ALL: "전체 부위",
  };

  if (!(partsData && moruData)) return "Loading...";
  return (
    <>
      {/* Action Buttons */}
      <div style={{ display: "flex", justifyContent: "end" }}>
        <button onClick={onClose} style={{ marginLeft: "10px" }}>
          취소
        </button>
        <button onClick={handleDoneClick} style={{ marginLeft: "10px" }}>
          완료
        </button>
      </div>
      <Container ref={containerRef} onClick={closeContextMenu}>
        {/* Parts Selector */}
        <PartsSelector>
          {/* Tabs */}
          <PartsTabs>
            {partsData.map((item, idx) => (
              <button
                key={idx}
                onClick={() => setSelectedTab(item.themePosition)}
                className={selectedTab === item.themePosition ? "selected" : ""}
              >
                {positionMap[item?.themePosition] || ""}
              </button>
            ))}
          </PartsTabs>
          {/* Parts List */}
          <PartsList>
            {partsData
              .find((item) => item.themePosition === selectedTab)
              ?.parentData?.map((part) => (
                <PartItem
                  key={part.partsKey}
                  onClick={(e) => handlePartClick(e, part)}
                >
                  <img src={part.partsImageUrl} alt={part.title} />
                  <span>{part.title}</span>
                </PartItem>
              ))}
          </PartsList>
        </PartsSelector>
        <div style={{ flex: 1 }}>
          {/* Base Character Selector */}
          <div className="flex items-center">
            <BaseCharacterSelector>
              <select
                value={selectedBaseCharacter.itemKey}
                onChange={(e) => {
                  const selectedCharacter = moruData.find(
                    (character) => character.itemKey === Number(e.target.value)
                  );
                  handleBaseCharacterChange(selectedCharacter);
                }}
              >
                {moruData.map((character, idx) => (
                  <option key={character.itemKey} value={character.itemKey}>
                    {character.name}
                  </option>
                ))}
              </select>
            </BaseCharacterSelector>
            {/* Front/Back Toggle */}
            <ToggleSwitch>
              <label className="switch">
                <input
                  type="checkbox"
                  checked={isFront}
                  onChange={(e) => {
                    setIsFront(e.target.checked);
                    setSelectedImageId(null);
                  }}
                />
                <div className="slider">
                  <span className="label-front ml-[10px]">앞</span>
                  <span className="label-back mr-[10px]">뒤</span>
                  <div className="slider-knob"></div>
                </div>
              </label>
            </ToggleSwitch>
          </div>

          {/* Toolbox */}
          <div className="flex justify-end">
            <Toolbox
              onCopy={handleCopy}
              onDelete={handleDelete}
              onBringForward={handleBringForward}
              onSendBackward={handleSendBackward}
              isDisabled={selectedImageId == null}
            />
          </div>
          {/* Canvas */}
          <CanvasContainer>
            <Stage
              width={getCanvasDimensions(scale).width}
              height={getCanvasDimensions(scale).height}
              ref={stageRef}
              onMouseDown={handleStageMouseDown}
              onContextMenu={(e) => e.evt.preventDefault()}
            >
              <Layer ref={gridLayerRef}>
                {/* Grid */}
                {drawGrid(scale)}
              </Layer>
              <Layer ref={itemLayerRef}>
                {/* Canvas Items */}
                {getCanvasItemsToRender().map((item) => {
                  if (item.type === "moru") {
                    return <MoruImage key={item.id} item={item} />;
                  } else if (item.type === "part") {
                    return (
                      <URLImage
                        key={item.id}
                        image={item}
                        isSelected={selectedImageId === item.id}
                        onSelect={() => setSelectedImageId(item.id)}
                        onChange={handleImageChange}
                        isFront={isFront}
                        scale={scale}
                      />
                    );
                  }
                  return null;
                })}
              </Layer>
            </Stage>
          </CanvasContainer>
          {/* Context Menu */}
          {contextMenu &&
            ReactDOM.createPortal(
              <ContextMenu
                style={{
                  top: contextMenu.mouseY,
                  left: "50%",
                }}
              >
                {contextMenu.part.childData.length
                  ? contextMenu.part.childData?.map((childPart) => (
                      <ContextMenuItem
                        key={childPart.partsKey}
                        onClick={() => handlePartSelect(childPart)}
                      >
                        <img
                          src={childPart.partsImageUrl}
                          alt={childPart.title}
                        />
                        <span>{childPart.title}</span>
                        <span
                          style={{
                            backgroundColor: childPart.colorHexCode,
                            width: "20px",
                            height: "20px",
                            display: "inline-block",
                          }}
                        ></span>
                      </ContextMenuItem>
                    ))
                  : null}
              </ContextMenu>,
              document.body
            )}
        </div>
        {/* Modal */}
        <InputBoardInfoModal
          defaultTags={tags}
          isOpen={isModalOpen}
          onClose={() => {
            setIsModalOpen(false);
          }}
          onSave={handleOpenPreviewModal}
          onTemporarySave={handleTemporarySave}
        />
      </Container>
      {!(partsData && moruData) || loading ? (
        <LoadingModal isOpen={loading} />
      ) : null}
    </>
  );
};

export default MoruCustomizer;
