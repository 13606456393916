// config.js
export const CANVAS_SIZE = 400; // in pixels
export const GRID_SIZE_MM = 2; // 1mm per grid unit
export const PIXEL_PER_MM = 5; // scaling factor: 10 pixels per mm
export const GRID_SIZE = GRID_SIZE_MM * PIXEL_PER_MM; // in pixels

// Helper function to convert mm to pixels
export const mmToPx = (mm, scale = 1) => {
  const mmPerInch = 25.4;
  const dpi = 96; // Adjust if necessary
  return (mm / mmPerInch) * dpi * scale;
};

export const pxToMm = (pixels, dpi = 96) => {
  const mmPerInch = 25.4;
  return (pixels / dpi) * mmPerInch;
};

// Calculate canvas dimensions based on scale
export const getCanvasDimensions = (scale) => ({
  width: CANVAS_SIZE * scale,
  height: CANVAS_SIZE * scale,
});
