import React, { useEffect, useCallback } from "react";
import Masonry from "react-masonry-css";
import styled from "styled-components";
import { useInView } from "react-intersection-observer";
import MyPageItem from "./MyPageItem";
import { useMyPage } from "../../contexts/MyPageContext";
import SearchTypeButtons from "./SearchTypeButtons";
import LoadingModal from "../Common/LoadingModal";

const MyPageContainer = styled.div`
  @apply container mx-auto p-4;
`;

const StyledMasonry = styled(Masonry)`
  display: flex;
  margin-left: -30px; /* gutter size offset */
  width: auto;
`;

const StyledMasonryColumn = styled.div`
  padding-left: 30px; /* gutter size */
  background-clip: padding-box;
`;

const LoadingOrEndMessage = styled.div`
  @apply text-center py-4 text-gray-600;
`;

const MyPageGrid = () => {
  const {
    works,
    hasMore,
    loading,
    error,
    fetchWorks,
    searchType,
    updateSearchType,
  } = useMyPage();

  const [ref, inView] = useInView({
    threshold: 0,
    rootMargin: "200px",
  });

  useEffect(() => {
    if (inView && hasMore && !loading) {
      fetchWorks();
    }
  }, [inView, hasMore, loading, fetchWorks]);

  const breakpointColumnsObj = {
    default: 4,
    1100: 3,
    700: 2,
    500: 1,
  };

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <MyPageContainer>
      <SearchTypeButtons />
      <StyledMasonry
        breakpointCols={breakpointColumnsObj}
        className="my-masonry-grid"
        columnClassName="my-masonry-grid_column"
      >
        {works.map((work) => {
          return (
            <StyledMasonryColumn key={work.id}>
              <MyPageItem
                work={work}
                boardKey={work.boardKey}
                isMyArt={searchType === "MY_ARTS"}
              />
            </StyledMasonryColumn>
          );
        })}
      </StyledMasonry>
      {hasMore && (
        <div ref={ref} style={{ height: "20px", margin: "20px 0" }} />
      )}
      {loading && <LoadingModal isOpen />}
      {!hasMore && (
        <LoadingOrEndMessage>마지막 페이지입니다.</LoadingOrEndMessage>
      )}
    </MyPageContainer>
  );
};

export default MyPageGrid;
