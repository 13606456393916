import React from "react";
import styled from "styled-components";
import { useGallery } from "../../contexts/GalleryContext";

const ButtonGroup = styled.div`
  @apply mb-4 space-x-2;
`;

const Button = styled.button`
  @apply px-4 py-2 bg-blue-500 text-white rounded mr-2 hover:bg-blue-600 transition-colors;
  &.active {
    @apply bg-blue-700;
  }
`;

const BoardTypeButtons = () => {
  const { boardType, updateBoardType } = useGallery();

  return (
    <ButtonGroup>
      <Button
        onClick={() => updateBoardType(null)}
        className={boardType === null ? "active" : ""}
      >
        전체
      </Button>
      <Button
        onClick={() => updateBoardType("MORU")}
        className={boardType === "MORU" ? "active" : ""}
      >
        모루인형
      </Button>
      <Button
        onClick={() => updateBoardType("BEADS")}
        className={boardType === "BEADS" ? "active" : ""}
      >
        비즈발
      </Button>
      <Button
        onClick={() => updateBoardType("GEM_STITCH")}
        className={boardType === "GEM_STITCH" ? "active" : ""}
      >
        보석십자수
      </Button>
    </ButtonGroup>
  );
};

export default BoardTypeButtons;
