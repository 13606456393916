import React, { useRef, useEffect, useState } from "react";
import styled from "styled-components";
import Modal from "../Common/Modal";
import { FaHeart } from "react-icons/fa";
import { MdClose } from "react-icons/md";
import { AiOutlineDownload } from "react-icons/ai";
import { useSwipeable } from "react-swipeable";
import { useProfile } from "../../contexts/ProfileContext";
import { AnimatePresence } from "framer-motion";

// Reuse the styled components from GalleryItem
const ItemContainer = styled.div`
  background-color: white;
  border-radius: 15px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  position: relative;
  max-width: 600px;
  transition: transform 0.2s ease-in-out;
  cursor: pointer;
`;

const ImageWrapper = styled.div`
  position: relative;
`;

const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const Reflection = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 20px;
  background: linear-gradient(transparent, rgba(0, 0, 0, 0.15));
`;

const Overlay = styled.div`
  padding: 15px;
  display: flex;
  flex-direction: column;
`;

const ProfileSection = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
`;

const ProfileImage = styled.img`
  width: 30px;
  height: 30px;
  border-radius: 50%;
  margin-right: 10px;
`;

const ProfileName = styled.span`
  font-weight: bold;
  font-size: 14px;
`;

const LikeSection = styled.div`
  display: flex;
  align-items: center;
  margin-left: auto;
  color: #ff6666;
`;

const LikeIcon = styled(FaHeart)`
  margin-right: 5px;
`;

const CardTitle = styled.h3`
  font-size: 16px;
  font-weight: bold;
  margin: 10px 0;
`;

const Tags = styled.div`
  margin-top: 10px;

  .tag {
    background-color: #f0f0f0;
    border-radius: 5px;
    padding: 5px;
    font-size: 12px;
    margin-right: 5px;
    display: inline-block;
  }
`;

const Button = styled.button`
  margin-top: 15px;
  padding: 10px;
  background-color: #000;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
`;

const TopRightButtons = styled.div`
  position: absolute;
  top: 10px;
  right: 10px;
  display: flex;
  gap: 10px;
`;

const SwiperButtons = styled.div`
  position: absolute;
  top: 40%;
  width: 100%;
  display: flex;
  justify-content: space-between;
  transform: translateY(-50%);
  z-index: 1; /* Ensure buttons are on top of the image */

  button {
    background: white;
    border: 1px solid black;
    font-size: 24px;
    cursor: pointer;
    border-radius: 3rem;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30px;
    height: 30px;

    &:hover {
      background: rgba(
        255,
        255,
        255,
        1
      ); /* Change on hover for better visibility */
    }
  }
`;

const PreviewPopup = ({ id, work, isOpen, onClose, handleSaveButton }) => {
  const modalRef = useRef(null);
  const { profile } = useProfile();
  const [currentIndex, setCurrentIndex] = useState(0); // For tracking front or back image

  const images = [work.frontImgUrl, work.backImgUrl]; // Front and back images

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }
    return () => {
      document.body.style.overflow = "unset";
    };
  }, [isOpen]);

  const handleClose = () => {
    onClose();
  };

  // Swipe handlers for swiping between front and back images
  const handlers = useSwipeable({
    onSwipedLeft: () =>
      setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length),
    onSwipedRight: () =>
      setCurrentIndex(
        (prevIndex) => (prevIndex - 1 + images.length) % images.length
      ),
    preventDefaultTouchmoveEvent: true,
    trackMouse: true, // Allows swiping with mouse on desktop
  });

  const handleDownloadButton = () => {
    const currentImageUrl = images[currentIndex];
    const link = document.createElement("a");
    link.href = currentImageUrl;
    link.download = `image_${currentIndex + 1}.jpg`; // File name can be customized
    document.body.appendChild(link); // Append the link
    link.click(); // Trigger download
    document.body.removeChild(link); // Clean up the DOM
  };

  return (
    <>
      <Modal isOpen={isOpen} onClose={handleClose}>
        {isOpen && (
          <div className="relative">
            <ImageWrapper {...handlers}>
              <Image
                src={images[currentIndex]}
                alt={`작품 이미지 ${currentIndex + 1}`}
              />
              <Reflection />
              <TopRightButtons>
                <button onClick={handleDownloadButton}>
                  다운로드
                  <AiOutlineDownload size={24} />
                </button>
                <MdClose size={24} onClick={handleClose} />
              </TopRightButtons>
            </ImageWrapper>

            <SwiperButtons>
              <button
                onClick={() =>
                  setCurrentIndex(
                    (prevIndex) =>
                      (prevIndex - 1 + images.length) % images.length
                  )
                }
              >
                &#10094;
              </button>
              <button
                onClick={() =>
                  setCurrentIndex(
                    (prevIndex) => (prevIndex + 1) % images.length
                  )
                }
              >
                &#10095;
              </button>
            </SwiperButtons>

            <Overlay>
              <ProfileSection>
                <ProfileImage src={profile.profileImgUrl} alt="Profile" />
                <ProfileName>{profile.nickName}</ProfileName>
                <LikeSection>
                  <LikeIcon />
                  <span>10,000</span>
                </LikeSection>
              </ProfileSection>

              <CardTitle> {work.comment}</CardTitle>
              <Tags>
                {Array.isArray(work.tags) &&
                  work.tags.map((tag, index) => (
                    <span key={index} className="tag">
                      #{tag}
                    </span>
                  ))}
              </Tags>

              <Button onClick={handleSaveButton}>저장</Button>
            </Overlay>
          </div>
        )}
      </Modal>
    </>
  );
};

export default PreviewPopup;
