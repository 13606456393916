import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import styled from "styled-components";
import Modal from "./Modal";
import MoruModalContent from "../../pages/projects/moru/MoruModalContent";
import { s3Url } from "../../static";

const PageContainer = styled.div`
  @apply container mx-auto mt-8 p-4;
`;

const Title = styled.h1`
  @apply text-3xl font-bold mb-6;
`;

const ThemeGrid = styled.div`
  @apply grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4;
`;

const ThemeCard = styled.button`
  @apply bg-white shadow-md rounded-lg p-4 hover:shadow-lg transition-shadow duration-200;
`;

const ThemeTitle = styled.h2`
  @apply text-xl font-semibold mb-2;
`;

const ThemeDescription = styled.p`
  @apply text-gray-600;
`;

const ProjectPage = ({ title, themes }) => {
  const [selectedTheme, setSelectedTheme] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const themeFromUrl = searchParams.get("theme");
    if (themeFromUrl) {
      setSelectedTheme(themeFromUrl);
      setIsModalOpen(true);
    }
  }, [location, themes]);

  const handleThemeClick = (theme) => {
    setSelectedTheme(theme);
    setIsModalOpen(true);
    // Update URL with the selected theme
    navigate(`?theme=${theme}`, { replace: true });
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    // Remove theme from URL when closing the modal
    navigate("", { replace: true });
  };

  return (
    <PageContainer>
      <img
        onClick={() => handleThemeClick("HALLOWEEN")}
        src={`${s3Url}/moru_key_visual.png`}
        alt="moru_key-visual"
        className="w-full min-h-[300px] sm:(w-full h-auto) cursor-pointer  object-cover"
      />

      <Modal isOpen={isModalOpen}>
        {selectedTheme && (
          <MoruModalContent theme={selectedTheme} onClose={handleCloseModal} />
        )}
      </Modal>
    </PageContainer>
  );
};

export default ProjectPage;
