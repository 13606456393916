import React, { useCallback, useState, useRef } from "react";
import styled from "styled-components";
import WorkPopup from "../Popup/WorkPopup";
import * as api from "../../api/apiClient";
import Modal from "../Common/Modal";
import MyArtOptions from "./MyArtOptions";
import MoruModalContent from "../../pages/projects/moru/MoruModalContent";

const ItemContainer = styled.div`
  @apply bg-white rounded-lg shadow-md overflow-hidden cursor-pointer transition-transform duration-200 hover:scale-105;
  position: relative; /* Important for relative positioning of the menu */
`;

const Image = styled.img`
  @apply w-full h-48 object-cover;
`;

const MyPageItem = ({ work, onImageLoad, boardKey, isMyArt }) => {
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [menuPosition, setMenuPosition] = useState(null);
  const [moruModalOpen, setMoruModalOpen] = useState(false);
  const itemRef = useRef(null);
  const [isWorkModalOpen, setIsWorkModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [moruData, setMoruData] = useState(null);

  const fetchWorkInfo = useCallback(async () => {
    try {
      setLoading(true);
      await api.fetchWorkInfo(boardKey).then((x) => {
        setIsWorkModalOpen(true);
        setLoading(false);
        setMoruData(x);
      });
    } catch (err) {
      console.error("Error fetching works:", err);
      setLoading(false); // Add this line to ensure loading is set to false on error
    }
  }, [boardKey]);

  const handleClick = (e) => {
    // If the context menu is already open, do nothing
    if (isPopupOpen) {
      setIsPopupOpen(false); // Open the context menu
      return;
    }

    if (!isMyArt) {
      fetchWorkInfo(boardKey);
    } else {
      // Get the position of the clicked item
      const rect = itemRef.current.getBoundingClientRect();
      setMenuPosition({
        top: rect.top + window.scrollY + rect.height, // Below the item
        left: rect.left + window.scrollX, // Align to the left of the item
      });
      setIsPopupOpen(true); // Open the context menu

      // setMoruModalOpen(true); // Open the context menu
    }
  };

  const handleClose = () => {
    setIsPopupOpen(false); // Close the context menu
  };

  const handleDelete = async () => {
    const value = window.confirm("삭제하시겠습니까?");

    if (!value) return;
    try {
      await api.deleteWork(boardKey);
      setIsPopupOpen(false); // Close the context menu after deletion
    } catch (err) {
      console.error("Error deleting work:", err);
    }
  };

  const handleUpdate = () => {
    api.getArtInfo(boardKey);
    setMoruModalOpen(true); // Open the update modal
    setIsPopupOpen(false); // Close the context menu
  };

  return (
    <>
      <ItemContainer onClick={handleClick} ref={itemRef}>
        <Image src={work.imageUrl} alt={work.title} onLoad={onImageLoad} />
      </ItemContainer>

      {/* If the context menu is open and it's the user's art */}
      {isPopupOpen && isMyArt ? (
        <MyArtOptions
          onDelete={handleDelete}
          onUpdate={handleUpdate}
          onClose={handleClose}
          position={menuPosition} // Position passed to context menu
        />
      ) : null}

      {/* Additional modal for another action */}
      {moruModalOpen ? (
        <Modal isOpen={moruModalOpen}>
          <MoruModalContent
            theme={"HALLOWEEN"}
            boardKey={boardKey}
            onClose={() => setMoruModalOpen(false)}
          />
        </Modal>
      ) : null}
      {!loading && isWorkModalOpen ? (
        <WorkPopup
          id={boardKey}
          work={{ ...moruData, profileImgUrl: work.profileImgUrl }}
          isOpen={isWorkModalOpen}
          onClose={() => setIsWorkModalOpen(false)}
          isLiked={work.isMyLike}
        />
      ) : null}
    </>
  );
};

export default MyPageItem;
